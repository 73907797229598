<template>
	<el-dialog title="专题报告" :close-on-click-modal="false" :visible.sync="subVisible" width="1000px">
		<el-card body-style="padding-left:0;padding-bottom: 0;">
			<el-form :model="dataForm" ref="dataForm" label-width="80px" :inline="true">
				<el-row style="margin-top: -5px;margin-bottom: 10px;">
					<el-col style="width: 420px;margin-left:20px">
						<el-form-item label="报告时间">
							<el-date-picker v-model="recContentTime" value-format="yyyy-MM-dd" style="width:300px"
								type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
                    </el-col>
                    <el-col style="width: 180px;margin-top:5px" >
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()">查询</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
        <el-row style="margin-top: 20px;">
            <el-table :row-style="selectRowBackground" class="dataListTable" :data="dataList"
                header-align="center" style="width: 100%; margin-bottom: 10px;" border
                :row-class-name="tableRowClassName" header-cell-class-name="dataListTHeader"
                @selection-change="handleSelectionChange" :cell-style="{ padding: '0px' }">
                <el-table-column type="selection" width="45" align="center"></el-table-column>
                <el-table-column label="NO." type="index" width="60" align="center">
                    <template slot-scope="scope">{{ (pageIndex2 - 1) * limit + scope.$index + 1
                    }}</template>
                </el-table-column>
                <el-table-column prop="fileName" label="报告名称" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="报告时间" width="150" align="center">
                </el-table-column>
            </el-table>
        </el-row>
		<el-row style="margin-top: 20px;">
            <el-col :span="24">
                <el-pagination class="my-pagination" @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle" :current-page="page" :page-sizes="[10, 20, 50, 100]"
                    :page-size="limit" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
			</el-col>
		</el-row>
         <el-row class="formBtnGroup">
             <el-col :span="24">
				<el-button class="btn" type="primary" size="small" @click="dataSubmit()" :disabled="tableSelVal.length==0"  >保存</el-button>
			</el-col>
         </el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "contact-select",
	data() {
		return {
			subVisible: true,
			dataList: [],
			recContentTime: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				beginTime: "",
				endTime: ""
			},
			limit: 10,
			page: 1,
            pageIndex2: 0,
			totalCount: 0,
			selectSpecialReportItems: [],
            tableSelVal: [],
			recIds : ''
		};
	},
	methods: {
		init(beginTime, endTime, recIds) {
			this.dataForm.beginTime = beginTime
			this.dataForm.endTime = endTime
			this.recIds = recIds
			this.$set(this.recContentTime, 0,beginTime ? beginTime : '')
			this.$set(this.recContentTime, 1,endTime ? endTime : '')
			this.$nextTick(() => {
				this.subVisible = true;
			})
			this.getDataList();
			
		},
		selGetDataList() {
			this.page = 1;
			this.getDataList();
		},
		resetFormData() {
			this.recContentTime = [];
		},
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
        sizeChangeHandle(val) {
			this.limit = val
			this.page = 1
			this.getDataList()
		},
		getDataList() {
			if (this.recContentTime == null) {
				this.recContentTime = ["",""];
			}

           // 先获取专题报告目录的id
			this.$http({
				url: this.$store.state.httpUrl + "/business/filekindconfig/getFileKindConfigRecId",
				method: 'post',
				data: this.$http.adornData({
                    workNo: $common.getItem("workNo"),
					kindName: '专题报告',
                })
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.specialReportId = data.body
					this.$http({
						url: this.$store.state.httpUrl + "/business/projectworkfileinfo/list",
						method: "get",
						params: {
							workNo: $common.getItem("workNo"),
							fileKind: this.specialReportId,
							fileName: this.fileName,
							limit: this.limit,
							page: this.page,
                            recIds: this.recIds,
                            startTime: this.recContentTime[0] ? this.recContentTime[0] : '',
					        completeTime: this.recContentTime[1] ? this.recContentTime[1] : '',
							type: 0
						},
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataList = data.body.records;
							this.totalCount = data.body.totalCount;
							this.pageIndex2 = this.page
						}
					});
				}
			})
            
		},
		dataSubmit() {
			this.$emit('selectSpecialReportItems', this.tableSelVal)
		},
        handleSelectionChange(val) {
			this.tableSelVal = val;
		},
       tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
        selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.recId);
			if (checkIdList.includes(row.recId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>

<style lang="scss" scoped="scoped">
.el-card {
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

</style>
