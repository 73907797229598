<template>
	<el-dialog :title="title" :close-on-click-modal="false" :visible.sync="subVisible" width="922px">
        <el-row>
            <div class="tiv">描述</div>
        </el-row>
		<el-row style="margin-top:10px;margin-bottom:10px">
			<el-input type="textarea" rows="3"
				v-model="dataForm.fileName" placeholder="请填写"></el-input>
		</el-row>
		 <el-row>
            <div class="tiv">
				照片上传
			</div>
        </el-row>
		<el-row style="margin-left:114px;margin-top:10px">
			<el-col :span="24">
				<el-upload ref="uploadSituation" class="upload-demo" action="action"
				style="margin-left: -115px;" :http-request="uploadSituation" multiple drag
				:show-file-list=false :auto-upload="true" accept="image/jpeg,image/png,image/jpg">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				</el-upload>
			</el-col>
		</el-row>
		<el-row style="margin-top:10px">
			<el-col :span="24">
				<el-table v-if="dataForm.photoList != undefined && dataForm.photoList.length > 0" border
					:data="dataForm.photoList" style="width: 100%;">
					<el-table-column type="index" label="No." width="60" align="center">
					</el-table-column>
					<el-table-column label="图片" width="180" align="center">
						<template slot-scope="scope">
							<el-image style="height: 100px" :src="scope.row.fileUri"
								fit='contain'></el-image>
						</template>
					</el-table-column>
					<el-table-column label="描述" width="500px" align="center">
						<template slot-scope="scope">
							<el-input type="textarea" rows="2"
								v-model="dataForm.photoList[scope.$index].fileName"
								placeholder="请填写"></el-input>
						</template>
					</el-table-column>
					<el-table-column label=" " width="120" align="center">
						<template slot-scope="scope">
							<el-button type="text" @click="delPhoto(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
         <el-row class="formBtnGroup">
             <el-col :span="24">
				<el-button class="btn" type="primary" size="small" @click="dataSubmit()">保存</el-button>
			</el-col>
         </el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "add-or-update-meeting",
	data() {
		return {
			subVisible: true,
			dataForm: {
				recNo: '',
				weeklyRecNo: '',
				dataType : '',
				sourceId: '',
				sourceType: 0,
				sort: 0,
				fileName: '',
				filePath : '',
				photoList: []
			},
			recNo: '',
			title: '',
			dataType: ''
		};
	},
	methods: {
		init(weeklyRecNo, recNo, sort, dataType) {
			// 标题
			if ( dataType === '030101') {
				if (!recNo) {
					this.title = '新增详细设计图纸审查进度情况'
				} else {
					this.title = '修改详细设计图纸审查进度情况'
				}
			} else if (dataType === '030102') {
				if (!recNo) {
					this.title = '新增设备资料审查进度情况'
				} else {
					this.title = '修改设备资料审查进度情况'
				}
			} else if (dataType === '030103') {
				if (!recNo) {
					this.title = '新增生产设计进展情况'
				} else {
					this.title = '修改生产设计进展情况'
				}
			} else if (dataType === '030200') {
				if (!recNo) {
					this.title = '新增采购进度情况'
				} else {
					this.title = '修改采购进度情况'
				}
			} else if (dataType === '030300') {
				if (!recNo) {
					this.title = '新增现场人力资源投入情况'
				} else {
					this.title = '修改现场人力资源投入情况'
				}
			}
			this.dataType = dataType
			console.log("dataType：" , this.dataType)
			console.log("recNo" , recNo)

			this.dataForm = {
				recNo: '',
				weeklyRecNo: weeklyRecNo,
				dataType : this.dataType,
				sourceId: '',
				sourceType: '',
				sort: sort,
				fileName: '',
				filePath : '',
				photoList: []
			}
			this.dataForm.recNo = recNo;
			this.$nextTick(() => {
				this.subVisible = true;
			})
			if (recNo) {
				this.getWeeklyMeetingDetail();
			}
			
		},
		getWeeklyMeetingDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportfile/getWeeklyMeetingDetail/" + this.dataForm.recNo,
				method: 'get',
				params: this.$http.adornParams({})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
				}
			})
            
		},
		uploadSituation(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadSituation.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					this.dataForm.photoList.push({
						filePath: res.body.fileSavePath,
						fileUri: res.body.fileUri,
						fileName: this.extractFileName(res.body.fileName),
						dataType: this.dataType,
						recNo: '',
						sourceId: '',
						sourceType: '',
						subcategory: this.dataForm.recNo
					});
					console.log("photoList：" , this.dataForm.photoList)
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		extractFileName(fileName) {
			let index = fileName.lastIndexOf(".");
			return fileName.substr(0, index)
		},
		delPhoto(index) {
			this.dataForm.photoList.splice(index, 1);
		},
		dataSubmit() {
			let _url = "/business/projectweeklyreportfile/saveMeetFile";
			if (this.dataForm.recNo && this.dataForm.recNo != 0) {
				_url = "/business/projectweeklyreportfile/updateMeetFile";
			}
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(this.dataForm)
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.subVisible = false;
							this.$emit("refreshDataList");
						}
					});
				}
			});
		},
	}
};
</script>

<style scoped="scoped">
.tiv {
    width: 135px !important;
	background-color: #E1F1FF;
	color: #00428E !important;
	font-weight: bold !important;
    padding: 10px !important;
    text-align: center;
    border: 1px solid #dadada;
}

.upload-demo /deep/ .el-upload-dragger {
	width: 468%;
	height: 100px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
	font-size: 40px;
	margin-top: 15px;
	line-height: 26px;
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
	line-height: 8px;
}

</style>
