<template>
	<el-dialog :title="dataForm.photoType == '1' ? '质量照片' : '进度照片'" :close-on-click-modal="false" :visible.sync="subVisible"
		width="1210px">
		<el-card body-style="padding-left:0;padding-bottom: 0;">
			<el-form :model="dataForm" ref="dataForm" label-width="80px" :inline="true">
				<el-row style="margin-top: -5px;margin-bottom: 10px;">
					<el-col style="width: 420px;margin-left:20px">
						<el-form-item label="登记时间">
							<el-date-picker v-model="recContentTime" value-format="yyyy-MM-dd" style="width:300px"
								type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<!-- <el-col style="width: 330px;">
						<el-form-item label="标的物" label-width="60px">
							<el-select id="subjectNo" v-model="dataForm.subjectNo" placeholder="请选择" style="width: 220px;"
								clearable>
								<el-option v-for="item in subjectList" :key="item.displayValue" :label="item.projectNo"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col style="width: 280px;">
						<el-form-item label="专业" label-width="50px">
							<el-select v-model="dataForm.major" placeholder="请选择" style="width: 180px;" clearable>
								<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col> -->
					<el-col style="width: 180px;margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()">检索</el-button>
                        <el-button v-preventReClick class="btn" type="primary" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div class="card-container" style="height: 450px;padding-top: 10px;">
			<div class="imgList" v-for="(row, rowIndex) in cardRows(dataList)" :key="rowIndex">
				<el-row>
					<div class="img-item" v-for="(item, colIndex) in row" :key="item.recNo"
						@click="clickCard(item, rowIndex, colIndex)">
						<el-col style="width: 180px;margin-left: 10px;">
							<el-card :body-style="{ padding: '0px' }" style="margin-bottom: 10px;height: 195px;"
								:class="{ active: checkActive(item) }">
								<el-image :src="item.photoPathUri" fit="scale-down" class="image"
									style="height: 120px;width: 168px;margin: 5px;display: block;"></el-image>
								<div style="padding: 2px 10px;">
									<div style="padding-bottom: 10px;height:50px;overflow: hidden;">
										<span>{{ item.photoContent }}</span>
									</div>
								</div>
							</el-card>
						</el-col>
					</div>
				</el-row>
			</div>
		</div>
        <el-row>
            <el-col :span="24">
                <el-pagination class="my-pagination" @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle" :current-page="page" :page-sizes="[10, 20, 50, 100]"
                    :page-size="limit" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
			</el-col>
		</el-row>
         <el-row class="formBtnGroup">
             <el-col :span="24">
				<el-button class="btn" type="primary" size="small" @click="dataSubmit()" :disabled="selectItems.length==0">保存</el-button>
			</el-col>
         </el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "qp-photo-select",
	data() {
		return {
			pageSpan: 12,
			subVisible: true,
			subjectList: [],
			majorList: [],
			dataList: [],
			recContentTime: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: '',
				major: '',
				photoType: '',
				beginTime: "",
				endTime: ""
			},
			limit: 12,
			page: 1,
			totalCount: 0,
			photoViewCol: 6,
			selectItems: [],
            recNos: ''
		};
	},
	methods: {
        cardRows(photoList) {
			const rows = [];
			for (let i = 0; i < photoList.length; i += this.photoViewCol) {
				rows.push(photoList.slice(i, i + this.photoViewCol));
			}
			return rows;
		},
		checkActive(item) {
			let act = this.selectItems.some(function (element) {
				return element.recNo == item.recNo
			})
			return act
		},
		clickCard(item, rowIndex, colIndex) {
			const idx = rowIndex * this.photoViewCol + colIndex
			let index = this.selectItems.indexOf(item)
			if (index == -1) {
				this.selectItems.push(item)
			} else {
				this.selectItems.splice(index, 1)[0];
			}
		},
		init(beginTime, endTime, photoType, subjectNo, recNos) {
            this.selectItems = []
			this.recNos = recNos
			this.dataForm.photoType = photoType
			this.dataForm.beginTime = beginTime
			this.dataForm.endTime = endTime
			this.dataForm.subjectNo = subjectNo
			// this.dataForm.major = ''
			this.$set(this.recContentTime, 0,beginTime ? beginTime : '')
			this.$set(this.recContentTime, 1,endTime ? endTime : '')
			this.$nextTick(() => {
				this.subVisible = true;
			})
			// this.getSubjectDropDown();
			// this.getMajorList();
			this.getDataList();
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
				}
			});
		},
		selGetDataList() {
			this.page = 1;
			this.getDataList();
		},
		resetFormData() {
			this.subjectNo = "";
			this.recContentTime = [];
			this.major = '';
		},
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
         sizeChangeHandle(val) {
			this.limit = val
			this.page = 1
			this.getDataList()
		},
		getDataList() {
			if (this.recContentTime == null) {
				this.recContentTime = ["",""];
			}
			this.$http({
				url: this.$store.state.httpUrl + "/business/qualityprogressphotoinfo/list",
				method: "get",
				params: {
					limit: this.limit,
					page: this.page,
					subjectNo: this.dataForm.subjectNo,
					photoType: this.dataForm.photoType,
					beginTime: this.recContentTime[0] ? this.recContentTime[0] : '',
					endTime: this.recContentTime[1] ? this.recContentTime[1] : '',
					workNo: $common.getItem("workNo"),
                    recNos: this.recNos
					// major: this.dataForm.major
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalCount = data.body.totalCount;
				}
			});
		},
		dataSubmit() {
            this.subVisible = false;
			this.$emit('selectPhotoItems', this.selectItems)
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.active {
	border: 1px solid #03a70b;
	-webkit-transform: rotate(0deg);
}

.active::before {
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
	border: 12px solid #03a70b;
	border-top-color: transparent;
	border-left-color: transparent;
}

.active::after {
	content: "";
	display: block;
	width: 5px;
	height: 10px;
	position: absolute;
	right: 4px;
	bottom: 5px;
	border: 1px solid #fff;
	border-top-color: transparent;
	border-left-color: transparent;
	transform: rotate(45deg);
	cursor: pointer;
}

.el-card {
	// display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.el-card .el-image {
	display: inline-block;
	text-align: center;
}
</style>
