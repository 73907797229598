<template>
	<!-- 监理新周报 -->
	<div class="mod-config">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" :inline="true" class="border-form">
			<el-card style="height: 100%;">
				<!-- <div class="elCardTitle">周报大纲</div> -->
				<el-row class="set_border">
					<el-col :span="8" class="col-set_border">
						<el-form-item label-width="120px" label="周报编号" class="item">
							<el-input class="sp-input" v-model="dataForm.weeklyName"
								placeholder="请输入" v-if="basicVisible">
							</el-input>
							<span v-if="!basicVisible">
								{{dataForm.weeklyName}}
							</span>
						</el-form-item>
					</el-col>
					<el-col :span="8" class="col-set_border">
						<el-form-item label-width="120px" label="时间" class="item">
							{{dataForm.weeklyTime}}
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label-width="120px" label="报告人" class="item">
							<el-input class="sp-input" v-model="dataForm.reportMan"
								placeholder="请输入" v-if="basicVisible"></el-input>
							<span class="set_span" v-if="!basicVisible">
								{{dataForm.reportMan}}
							</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="set_border">
					<el-col :span="16" class="col-set_border">
						<el-form-item label-width="120px" label="工程名称" class="item">
							<span class="set_span">
								{{dataForm.projectName}}
							</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label-width="120px" label="时间" class="item">
							<el-date-picker v-model="dataForm.reportTime" type="date"
								value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 260px;"
								v-if="basicVisible">
							</el-date-picker>
							<span class="set_span" v-if="!basicVisible">
								{{dataForm.reportTime}}
							</span>
						</el-form-item>
					</el-col>
				</el-row>
                <el-row class="set_border">
					<el-col :span="8" class="col-set_border">
						<el-form-item label-width="120px" label="委托单位" class="item">
							<span class="set_span">
								{{dataForm.projectEntrust}}
							</span>
						</el-form-item>
					</el-col>
                    <el-col :span="8" class="col-set_border">
						<el-form-item label-width="120px" label="承包单位" class="item">
							<span class="set_span">
								{{dataForm.contractCompany}}
							</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label-width="120px" label="项目总监" class="item">
							<!-- {{dataForm.refirmMan}} -->
							<el-input class="sp-input" v-model="dataForm.refirmMan"
								placeholder="请输入" v-if="basicVisible"></el-input>
							<span class="set_span" v-if="!basicVisible">
								{{dataForm.refirmMan}}
							</span>
						</el-form-item>
					</el-col>
				</el-row>
                <el-row class="set_border">
					<el-col :span="16" class="col-set_border">
						<el-form-item label-width="120px" label="工作控制号" class="item">
							<span class="set_span">
								{{dataForm.workNo}}
							</span>
						</el-form-item>
					</el-col>
                    <el-col :span="8">
						<el-form-item label-width="120px" label="时间" class="item">
							<!-- {{dataForm.refirmTime}} -->
							<el-date-picker v-model="dataForm.refirmTime" type="date"
								value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 260px;"
								v-if="basicVisible">
							</el-date-picker>
							<span class="set_span" v-if="!basicVisible">
								{{dataForm.refirmTime}}
							</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="set_border">
					<el-col :span="23" class="selBtnGroup">
						<el-button class="btn" type="primary" size="small" @click="updateBasicHandle()" v-if="!basicVisible" :disabled="updateFlg || addFlg">修改</el-button>
						<el-button class="btn" type="primary" size="small" @click="saveBasicHandle()" v-if="basicVisible" :disabled="updateFlg || addFlg ">保存</el-button>
					</el-col>
				</el-row>
				<div class="titleDiv">一、概述</div>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周生产进度</span>
                            <el-radio-group v-model="dataForm.productProgressStatus" @change="saveBasicHandle()">
								<el-radio label="0">正常</el-radio>
								<el-radio label="1">缓慢</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">总体进度</span>
							 <el-radio-group v-model="dataForm.overallProgressStatus" @change="saveBasicHandle()">
								<el-radio label="0">正常</el-radio>
								<el-radio label="1">缓慢</el-radio>
								<el-radio label="2">已延期</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周工程质量状况</span>
							 <el-radio-group v-model="dataForm.weekQualityStatus" @change="saveBasicHandle()">
								<el-radio label="0">正常</el-radio>
								<el-radio label="1">不满意</el-radio>
								<el-radio label="2">待改进</el-radio>
							 </el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周现场安全情况</span>
							 <el-radio-group v-model="dataForm.weekSafeStatus" @change="saveBasicHandle()">
								<el-radio label="0">正常</el-radio>
								<el-radio label="1">不满意</el-radio>
								<el-radio label="2">待改进</el-radio>
							 </el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周发出监理工作联系单</span>
                            <div class="summaryvalueSpan" >
                                <span>本周</span>
								<span class="summaryvalue"> {{dataForm.contactNum}} </span>
								<span>份，累计</span>
								<span class="summaryvalue"> {{dataForm.totalContactNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周发出监理工作通知单</span>
                            <div class="summaryvalueSpan" >
                                <span>本周</span>
								<span class="summaryvalue"> {{dataForm.noticeNum}} </span>
								<span>份，累计</span>
								<span class="summaryvalue"> {{dataForm.totalNoticeNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周发出监理工作专题报告</span>
                            <div class="summaryvalueSpan" >
                                <span>本周</span>
								<span class="summaryvalue"> {{dataForm.specialReportNum}} </span>
								<span>份，累计</span>
								<span class="summaryvalue"> {{dataForm.totalSpecialReportNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周发出不一致报告</span>
                            <div class="summaryvalueSpan" >
                                <span>本周</span>
								<span class="summaryvalue"> {{dataForm.disAccordNum}} </span>
								<span>份，累计</span>
								<span class="summaryvalue"> {{dataForm.totalDisAccordNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周发出会议纪要</span>
                            <div class="summaryvalueSpan" >
                                <span>本周</span>
								<span class="summaryvalue"> {{dataForm.meetNum}} </span>
								<span>份，累计</span>
								<span class="summaryvalue"> {{dataForm.totalMeetNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="summaryLabelspan">本周审查工艺文件</span>
                            <div class="summaryvalueSpan" >
                                <span>本周</span>
								<span class="summaryvalue"> {{dataForm.fileCheckNum}}  </span>
								<span>份，累计</span>
								<span class="summaryvalue"> {{dataForm.totalFileCheckNum}}  </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" class="summaryLabelspan1">
						<el-form-item label-width="0">
							<span>本周检验项目</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-table class="dataListTable" :data="dataForm.testItemRecords" header-align="center" border 
						:span-method="objectTestRecordMethod" style="width: 100%" header-cell-class-name="dataListTHeader" 
						:cell-style="{ padding: '0px' }">
							<el-table-column prop="subjectNo" label="船号" width="120" align="center">
							</el-table-column>
							<el-table-column prop="majorName" label="专业" width="120" align="center">
							</el-table-column>
							<el-table-column prop="testConclusionCount" label="AA" align="center">
								<el-table-column prop="testConclusionCount" label="接受" align="center">
								</el-table-column>
							</el-table-column>
							<el-table-column prop="testConclusionWhereCount" label="QCC" align="center">
								<el-table-column prop="testConclusionWhereCount" label="有条件接受" align="center">
								</el-table-column>
							</el-table-column>
							<el-table-column prop="recheckAfterCorrectionCount" label="OWC" align="center">
								<el-table-column prop="recheckAfterCorrectionCount" label="修正后复检" align="center">
								</el-table-column>
							</el-table-column>
							<el-table-column prop="testConclusionRefuseCount" label="NA" align="center">
								<el-table-column prop="testConclusionRefuseCount" label="不接受" align="center">
								</el-table-column>
							</el-table-column>
							<el-table-column prop="testConclusionTotalCount" label="总计 " align="center">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row style="margin-top:10px">
					<el-col :span="24" class="summaryLabelspan1">
						<el-form-item label-width="0">
							<span>本周整改项目</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-table class="dataListTable" :data="dataForm.patrolItemRecords" header-align="center" border :span-method="objectTestRecordMethod"
							style="width: 100%;" header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
							<el-table-column prop="subjectNo" label="工程编号" width="120" align="center">
							</el-table-column>
							<el-table-column prop="majorName" label="专业" width="120" align="center">
							</el-table-column>
							<el-table-column prop="applyNum" label="本周内提出整改数" align="center">
							</el-table-column>
							<el-table-column prop="alreadyModifyNum" label="截至本周已整改数" align="center">
							</el-table-column>
							<el-table-column prop="notFinishNum" label="截止本周未整改数 " align="center">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row style="margin-top:20px">
					<el-col :span="24" class="summaryLabelspan1">
						<el-form-item label-width="0">
							<span>主要建造大节点</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<el-table max-height="600" :row-class-name="tableRowClassName" :span-method="objectSpanMethod"
						ref="dataListTable" class="dataListTable" border :data="nodeList"
						header-align="center" header-cell-class-name="dataListTHeader"
						:cell-style="{ padding: '0px' }" style="margin-bottom: 20px;">
							<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && v.nodeId === '0'"
								:label="v.nodeName" :prop="v.nodeId" align="center" width="130px">
									<template slot-scope="scope">
									<span style="font-weight:bolder">
										{{scope.row.subjectName }}
									</span>
									</template>
							</el-table-column>
							<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && v.nodeId === '1'"
								:label="v.nodeName" :prop="v.nodeId" align="center" width="60px">
									<template slot-scope="scope">
									<span style="font-weight:bolder">
										{{scope.row.title}}
									</span>
									</template>
							</el-table-column>
							<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && idx > 1"
								:label="v.nodeName" :prop="v.nodeId" align="center">
									<template slot-scope="scope">
									<span>
										{{scope.row.contentList[idx-2].colValue }}
									</span>
									</template>
							</el-table-column>
							<el-table-column align="left" label="" v-if="headers != undefined && headers.length == 2">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<div class="titleDiv">二、本周主要事件综述</div>
				<el-row>
					<draggable v-model="dataForm.mainEventDescList" @start="onDragStart('020000')" @update="onDragEnd">
						<el-col :span="24" v-for="(mainEventDesc, index) in dataForm.mainEventDescList" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non" v-if="mainEventDesc.updUserName != undefined && mainEventDesc.updUserName != ''">
								<el-col :span="17" v-if="mainEventDesc.recNo == '' || (updateFlg && mainEventDesc.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="mainEventDesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{mainEventDesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="mainEventDesc.updUserName != undefined && mainEventDesc.updUserName != '' && mainEventDesc.updDate != undefined && mainEventDesc.updDate != ''">
										{{ mainEventDesc.updUserName}} {{mainEventDesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="mainEventDesc.recNo == '' || (updateFlg && mainEventDesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(mainEventDesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="mainEventDesc.recNo == '' || (updateFlg && mainEventDesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && mainEventDesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(mainEventDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && mainEventDesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(mainEventDesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
							<!-- 继承的数据添加背景色-->
							<el-col :span="23" class="set_bc" v-if="mainEventDesc.updUserName == undefined || mainEventDesc.updUserName == ''">
								<el-col :span="17" v-if="mainEventDesc.recNo == '' || (updateFlg && mainEventDesc.recNo == updateDescRecNo)">
									<el-input class="desc_input_bc" type="textarea" autosize v-model="mainEventDesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span_color" v-else>
									{{mainEventDesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="mainEventDesc.updUserName != undefined && mainEventDesc.updUserName != '' && mainEventDesc.updDate != undefined && mainEventDesc.updDate != ''">
										{{ mainEventDesc.updUserName}} {{mainEventDesc.updDate}}
									</span>
								</el-col>
								<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="mainEventDesc.recNo == '' || (updateFlg && mainEventDesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(mainEventDesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="mainEventDesc.recNo == '' || (updateFlg && mainEventDesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(mainEventDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(mainEventDesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-top:10px">
						<el-button class="btn" type="primary" size="small" @click="addMainEventHandle()" :disabled="updateFlg || addFlg || basicVisible"> + 事件综述 </el-button>
					</el-col>
				</el-row>
				<div class="titleDiv">三、建造进度综述</div>
				<el-row>
					<div class="tr_title_row">
						（一）设计进展情况
					</div>
				</el-row>
				<el-row>
					<div class="tr_title_row1">
						1、详细设计图纸审查进度情况
					</div>
				</el-row>
				<el-row>
					<!-- <draggable v-model="dataForm.designDrawingList" @start="onDragStart('030101')" @update="onDragEnd"> -->
						<div v-for="(drawing, index) in dataForm.designDrawingList" :key="'drawing' + index">
							<el-row class="meet_row">
								<el-col :span="24" style="margin-bottom: 35px;">
									<div class="mc_div">
										{{drawing.fileName}}
									</div>
								</el-col>
								<el-row v-for="(photo, index1) in drawing.photoList" :key="'rw' + index1" style="margin-top: 15px">
									<el-col :span="24" style="margin-left:20px;">
										<el-image style="min-height: 220px;" :src="photo.fileUri" fit='contain'></el-image>
									</el-col>
								</el-row>
								<el-col :span="24">
									<div style="float: right;margin-right: 20px;margin-top:20px">
										<el-button type="text" class="button" style="margin-right: 10px;"
											@click="addOrUpdateSituation(drawing.recNo, '030101')" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
										<el-button type="text" class="button"
											@click="deleteWeeklyReportFile(drawing.recNo, '030101')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
									</div>
								</el-col>
							</el-row>
						</div>
					<!-- </draggable> -->
					<el-row class="sesit">
						<el-button class="sbtn" type="primary" size="small" @click="addOrUpdateSituation('', '030101')" :disabled="updateFlg || addFlg || basicVisible"> 添加详细设计图纸审查进度情况 </el-button>
					</el-row>
				</el-row>
				<el-row>
					<div class="tr_title_row1">
						2、设备资料审查进度情况
					</div>
				</el-row>
				<el-row>
					<!-- <draggable v-model="dataForm.equipmentDataReviewList" @start="onDragStart('030102')" @update="onDragEnd"> -->
						<div v-for="(review, index) in dataForm.equipmentDataReviewList" :key="'review' + index">
							<el-row class="meet_row">
								<el-col :span="24" style="margin-bottom: 35px;">
									<div class="mc_div">
										{{review.fileName}}
									</div>
								</el-col>
								<el-row v-for="(photo, index1) in review.photoList" :key="'rw' + index1" style="margin-top: 15px">
									<el-col :span="24" style="margin-left:20px;">
										<el-image style="min-height: 220px;" :src="photo.fileUri" fit='contain'></el-image>
									</el-col>
								</el-row>
								<el-col :span="24">
									<div style="float: right;margin-right: 20px;margin-top:20px">
										<el-button type="text" class="button" style="margin-right: 10px;"
											@click="addOrUpdateSituation(review.recNo, '030102')" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
										<el-button type="text" class="button"
											@click="deleteWeeklyReportFile(review.recNo, '030102')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
									</div>
								</el-col>
							</el-row>
						</div>
					<!-- </draggable> -->
					<el-row class="sesit">
						<el-button class="sbtn" type="primary" size="small" @click="addOrUpdateSituation('', '030102')" :disabled="updateFlg || addFlg || basicVisible"> 添加设备资料审查进度情况 </el-button>
					</el-row>
				</el-row>
				<el-row>
					<div class="tr_title_row1">
						3、生产设计进展情况
					</div>
				</el-row>
				<el-row>
					<!-- <draggable v-model="dataForm.productionDesignList" @start="onDragStart('030103')" @update="onDragEnd"> -->
						<div v-for="(design, index) in dataForm.productionDesignList" :key="'design' + index">
							<el-row class="meet_row">
								<el-col :span="24" style="margin-bottom: 35px;">
									<div class="mc_div">
										{{design.fileName}}
									</div>
								</el-col>
								<el-row v-for="(photo, index1) in design.photoList" :key="'rw' + index1" style="margin-top: 15px">
									<el-col :span="24" style="margin-left:20px;">
										<el-image style="min-height: 220px;" :src="photo.fileUri" fit='contain'></el-image>
									</el-col>
								</el-row>
								<el-col :span="24">
									<div style="float: right;margin-right: 20px;margin-top:20px">
										<el-button type="text" class="button" style="margin-right: 10px;"
											@click="addOrUpdateSituation(design.recNo, '030103')" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
										<el-button type="text" class="button"
											@click="deleteWeeklyReportFile(design.recNo, '030103')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
									</div>
								</el-col>
							</el-row>
						</div>
					<!-- </draggable> -->
					<el-row class="sesit">
						<el-button class="sbtn" type="primary" size="small" @click="addOrUpdateSituation('', '030103')" :disabled="updateFlg || addFlg || basicVisible"> 添加生产设计进展情况 </el-button>
					</el-row>
				</el-row>
				<el-row>
					<div class="tr_title_row">
						（二）采购进度情况
					</div>
				</el-row>
				<el-row>
					<!-- <draggable v-model="dataForm.procurementProgressList" @start="onDragStart('030200')" @update="onDragEnd"> -->
						<div v-for="(procurement, index) in dataForm.procurementProgressList" :key="'procurement' + index">
							<el-row class="meet_row">
								<el-col :span="24" style="margin-bottom: 35px;" >
									<div class="mc_div">
										{{procurement.fileName}}
									</div>
								</el-col>
								<el-row v-for="(photo, index1) in procurement.photoList" :key="'rw' + index1" style="margin-top: 15px">
									<el-col :span="24" style="margin-left:20px;">
										<el-image style="min-height: 220px;" :src="photo.fileUri" fit='contain'></el-image>
									</el-col>
								</el-row>
								<el-col :span="24">
									<div style="float: right;margin-right: 20px;margin-top:20px">
										<el-button type="text" class="button" style="margin-right: 10px;"
											@click="addOrUpdateSituation(procurement.recNo, '030200')" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
										<el-button type="text" class="button"
											@click="deleteWeeklyReportFile(procurement.recNo, '030200')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
									</div>
								</el-col>
							</el-row>
						</div>
					<!-- </draggable> -->
					<el-row class="sesit">
						<el-button class="sbtn" type="primary" size="small" @click="addOrUpdateSituation('', '030200')" :disabled="updateFlg || addFlg || basicVisible"> 添加采购进度情况 </el-button>
					</el-row>
				</el-row>
				<el-row>
					<div class="tr_title_row">
						（三） 现场生产进度情况
					</div>
				</el-row>
				<el-row>
					<div class="tr_title_row1">
						现场人力资源投入情况
					</div>
				</el-row>
				<el-row>
					<draggable v-model="dataForm.onsiteProductionProgressList" @start="onDragStart('030300')" @update="onDragEnd">
						<div v-for="(onsite, index) in dataForm.onsiteProductionProgressList" :key="'onsite' + index">
							<el-row class="meet_row">
								<el-col :span="24" style="margin-bottom: 35px;">
									<div class="mc_div">
										{{onsite.fileName}}
									</div>
								</el-col>
								<el-row v-for="(photo, index1) in onsite.photoList" :key="'rw' + index1" style="margin-top: 15px">
									<el-col :span="24" style="margin-left:20px;">
										<el-image style="min-height: 220px;" :src="photo.fileUri" fit='contain'></el-image>
									</el-col>
								</el-row>
								<el-col :span="24">
									<div style="float: right;margin-right: 20px;margin-top:20px">
										<el-button type="text" class="button" style="margin-right: 10px;"
											@click="addOrUpdateSituation(onsite.recNo, '030300')" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
										<el-button type="text" class="button"
											@click="deleteWeeklyReportFile(onsite.recNo, '030300')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
									</div>
								</el-col>
							</el-row>
						</div>
					</draggable>
					<el-row class="sesit">
						<el-button class="sbtn" type="primary" size="small" @click="addOrUpdateSituation('', '030300')" :disabled="updateFlg || addFlg || basicVisible"> 添加现场人力资源投入情况 </el-button>
					</el-row>
				</el-row>
				<div v-for="(constructionProgress, index) in dataForm.constructionProgressList" :key="index">
					<el-row>
						<div class="card" v-for="(photo, index1) in constructionProgress.fileList" :key="index1"
							:style="{ order: photo.sort }" @dragstart="dragStart(index, index1)" @dragover.prevent
							@drop="drop(index, index1, '3')" @dragend="dragEnd(index, '3')" draggable="true">
							<el-col :span="24">
								<el-card :body-style="{ padding: '15px' }"
									style="margin-bottom: 10px;margin-right: 15px;min-height: 315px;">
									<el-image :src="photo.fileUri"
										class="image"></el-image>
									<div style="padding: 2px 10px;">
										<div class="t-column-ellipsis" style="margin-top:5px">
											{{photo.fileName}}
										</div>
										<div style="float:right">
											<el-button type="text" class="button" style="margin-right: 10px;"
												@click="updateImgContent(photo.recNo, photo.fileName)" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
											<el-button type="text" class="button"
												@click="deleteWeeklyReportFile(photo.recNo, '030000')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
										</div>
									</div>
								</el-card>
							</el-col>
						</div>
					</el-row>
					<el-row class="sesit">
						<el-upload class="addFileBox" ref="uploadConstructionProgressPhotos" :action="action" multiple
						  :http-request="uploadConstructionProgressPhotos"  accept="image/jpeg,image/png,image/jpg" 
						  :data="{ index: index, subjectNo: constructionProgress.subjectNo }">
						  <el-button  class="btn" type="primary" size="small" :disabled="updateFlg || addFlg || basicVisible">+ 插入进度图片</el-button>
						</el-upload>
						<!-- <el-button class="btn" type="primary" size="small" @click="addConProgressPhotoHandle()" > + 插入进度图片 </el-button> -->
					</el-row>
					<el-row class="cp_row">
						<el-row class="cp_sub_row">
							{{constructionProgress.subjectName}}
						</el-row>
						<div v-for="(conProgress, index1) in constructionProgress.conProgressList" :key="index1">
							<el-row class="cp_maj_row">
								{{conProgress.majorName}}
							</el-row>
							<div v-for="(progress, index2) in conProgress.progressList" :key="index2">
								<el-row>
									<el-col :span="24"  class="cp_sc_div">
										<div>
											{{progress.subcategoryName}}
										</div>	
									</el-col>
									<!-- <div class="cp_sc_div">
										{{progress.subcategoryName}}
									</div> -->
								</el-row>
								<el-row>
									<el-table :row-class-name="tableRowClassName" ref="dataListTable" class="dataListTable" border
										v-if="progress.headers != undefined && progress.headers.length > 0" :data="progress.recordList"
										header-align="center" header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }"
										style="margin:10px;width:97%">
										<el-table-column label="NO." width="60" align="center" type="index">
										</el-table-column>
										<el-table-column v-for="(v, idx) in progress.headers" v-if="idx == 0" :key="idx"
											:label="v.subProgressName" :prop="v.colName" align="left" min-width="150px">
											<template slot-scope="scope">
												<span>{{ scope.row.itemName }}</span>
											</template>
										</el-table-column>
										<el-table-column v-for="(v, idx) in progress.headers"
											v-if="progress.headers.length > 1 && idx >= 1" :key="idx" :label="v.subProgressName"
											:prop="v.colName" align="center" min-width="130px">
											<template slot-scope="scope">
												<span>{{scope.row.contentList[idx].colValue }}</span>
											</template>
										</el-table-column>
										<el-table-column label="备注" width="200" align="center">
											<template slot-scope="scope">
												<span>{{ scope.row.remark }}</span>
											</template>
										</el-table-column>
									</el-table>
								</el-row>
								<el-row>
									<draggable v-model="progress.progressDescList" @start="onDragStart('030000', index, index1, index2)" @update="onDragEnd">
										<el-col :span="24" v-for="(progressDesc, index3) in progress.progressDescList" :key="index3">
											<el-col :span="1">
												<i class="el-icon-position"></i>
											</el-col>
											<el-col :span="23" class="set_non" v-if="progressDesc.updUserName != undefined && progressDesc.updUserName != ''">
												<el-col :span="17" v-if="progressDesc.recNo == '' || (updateFlg && progressDesc.recNo == updateDescRecNo)">
													<el-input class="desc_input1" type="textarea" autosize  v-model="progressDesc.descContent"></el-input>
												</el-col>
												<el-col :span="17" class="desc_span" v-else>
													{{progressDesc.descContent}}
												</el-col>
												<el-col :span="4" class="un_col">
													<span class="un_span" v-if="progressDesc.updDate != undefined && progressDesc.updDate != ''">
														{{ progressDesc.updUserName}} {{progressDesc.updDate}}
													</span>
												</el-col>
												<!--新增和修改时按钮操作-->
												<el-col :span="1" v-if="progressDesc.recNo == '' || (updateFlg && progressDesc.recNo == updateDescRecNo)">
													<el-button v-preventReClick type="text" size="small" class="desc_btn"
													@click="saveDescHandle(progressDesc)"> 保存 </el-button>
												</el-col>
												<el-col :span="1" v-if="progressDesc.recNo == '' || (updateFlg && progressDesc.recNo == updateDescRecNo)">
													<el-button v-preventReClick type="text" size="small" class="desc_btn"
													@click="cancelDescHandle()"> 取消 </el-button>
												</el-col>
												<!--非新增和修改时按钮操作-->
												<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && progressDesc.showButton == 1">
													<el-button v-preventReClick type="text" size="small" class="desc_btn"
													@click="updateDescHandle(progressDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
												</el-col>
												<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && progressDesc.showButton == 1">
													<el-button v-preventReClick type="text" size="small" class="desc_btn"
													@click="deleteDescHandle(progressDesc.recNo)"> × 删除</el-button>
												</el-col>
											</el-col>
											<!-- 继承的数据添加背景色-->
											<el-col :span="23" class="set_bc" v-if="progressDesc.updUserName == undefined || progressDesc.updUserName == ''">
												<el-col :span="17" v-if="progressDesc.recNo == '' || (updateFlg && progressDesc.recNo == updateDescRecNo)">
													<el-input class="desc_input_bc" type="textarea" autosize v-model="progressDesc.descContent"></el-input>
												</el-col>
												<el-col :span="17" class="desc_span_color" v-else>
													{{progressDesc.descContent}}
												</el-col>
												<el-col :span="4" class="un_col">
													<span class="un_span" v-if="progressDesc.updDate != undefined && progressDesc.updDate != ''">
														{{ progressDesc.updUserName}} {{progressDesc.updDate}}
													</span>
												</el-col>
												<!--新增和修改时按钮操作-->
												<el-col :span="1" v-if="progressDesc.recNo == '' || (updateFlg && progressDesc.recNo == updateDescRecNo)">
													<el-button v-preventReClick type="text" size="small" class="desc_btn"
													@click="saveDescHandle(progressDesc)"> 保存 </el-button>
												</el-col>
												<el-col :span="1" v-if="progressDesc.recNo == '' || (updateFlg && progressDesc.recNo == updateDescRecNo)">
													<el-button v-preventReClick type="text" size="small" class="desc_btn"
													@click="cancelDescHandle()"> 取消 </el-button>
												</el-col>
												<!--非新增和修改时按钮操作-->
												<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
													<el-button v-preventReClick type="text" size="small" class="desc_btn"
													@click="updateDescHandle(progressDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
												</el-col>
												<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
													<el-button v-preventReClick type="text" size="small" class="desc_btn"
													@click="deleteDescHandle(progressDesc.recNo)"> × 删除</el-button>
												</el-col>
											</el-col>
										</el-col>
									</draggable>
									<el-col :span="24" style="margin:10px">
										<el-button class="btn" type="primary" size="small" @click="addConstructionProgressHandle(index, index1, index2)" :disabled="updateFlg || addFlg || basicVisible"> + 追加记录 </el-button>
									</el-col>
								</el-row>
							</div>
						</div>
					</el-row>
				</div>
				<div class="titleDiv">四、建造检验和质量综述</div>
				<el-row>
					<div class="tr_title_row">
						4.1 本周项目检验综述
					</div>
				</el-row>
				<div v-for="(testRecordSummary, index) in dataForm.testRecordSummaryList" :key="'testRecord' + index">
					<el-row class="cp_row">
						<el-row class="cp_sub_row">
							{{testRecordSummary.subjectName}}
						</el-row>
						<div v-for="(testRecord, index1) in testRecordSummary.testRecordList" :key="'testRecord' + index1">
							<el-row class="cp_maj_row">
								{{testRecord.majorName}}
							</el-row>
							<el-row>
								<el-table :row-class-name="tableRowClassName" ref="dataListTable" class="dataListTable" border
									:data="testRecord.recordList" header-align="center" header-cell-class-name="dataListTHeader" 
									:cell-style="{ padding: '0px' }" style="margin:10px;width:97%">
									<el-table-column type="index" label="No." align="center" width="80">
									</el-table-column>
									<el-table-column prop="probContext" label="检验项目" align="left" show-overflow-tooltip>
									</el-table-column>
									<el-table-column prop="testDate" label="检验时间" align="center" width="100">
									</el-table-column>
									<el-table-column prop="sutContent" label="检验意见" align="left" show-overflow-tooltip>
									</el-table-column>
									<el-table-column prop="testConclusionName" label="检验结论" align="center" width="150">
									</el-table-column>
									<el-table-column prop="testMan" label="检验人" align="center" width="120">
									</el-table-column>
								</el-table>
							</el-row>
							<el-row>
								<draggable v-model="testRecord.testRecordDescList" @start="onDragStart('040100', index, index1, '')" @update="onDragEnd">
									<el-col :span="24" v-for="(testRecordDesc, index2) in testRecord.testRecordDescList" :key="'testRecord' + index2">
										<el-col :span="1">
											<i class="el-icon-position"></i>
										</el-col>
										<el-col :span="23" class="set_non" v-if="testRecordDesc.updUserName != undefined && testRecordDesc.updUserName != ''">
											<el-col :span="17" v-if="testRecordDesc.recNo == '' || (updateFlg && testRecordDesc.recNo == updateDescRecNo)">
												<el-input class="desc_input1" type="textarea" autosize  v-model="testRecordDesc.descContent"></el-input>
											</el-col>
											<el-col :span="17"class="desc_span" v-else>
												{{testRecordDesc.descContent}}
											</el-col>
											<el-col :span="4" class="un_col">
												<span class="un_span" v-if="testRecordDesc.updDate != undefined && testRecordDesc.updDate != ''">
													{{ testRecordDesc.updUserName}} {{testRecordDesc.updDate}}
												</span>
											</el-col>
											<!-- 占位 -->
											<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
											<!--新增和修改时按钮操作-->
											<el-col :span="1" v-if="testRecordDesc.recNo == '' || (updateFlg && testRecordDesc.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="saveDescHandle(testRecordDesc)"> 保存 </el-button>
											</el-col>
											<el-col :span="1" v-if="testRecordDesc.recNo == '' || (updateFlg && testRecordDesc.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="cancelDescHandle()"> 取消 </el-button>
											</el-col>
											<!--非新增和修改时按钮操作-->
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && testRecordDesc.showButton == 1">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="updateDescHandle(testRecordDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
											</el-col>
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && testRecordDesc.showButton == 1">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="deleteDescHandle(testRecordDesc.recNo)"> × 删除</el-button>
											</el-col>
										</el-col>
										<!-- 继承的数据添加背景色-->
										<el-col :span="23" class="set_bc" v-if="testRecordDesc.updUserName == undefined || testRecordDesc.updUserName == ''">
											<el-col :span="17" v-if="testRecordDesc.recNo == '' || (updateFlg && testRecordDesc.recNo == updateDescRecNo)">
												<el-input class="desc_input_bc" type="textarea" autosize v-model="testRecordDesc.descContent"></el-input>
											</el-col>
											<el-col :span="17" class="desc_span_color" v-else>
												{{testRecordDesc.descContent}}
											</el-col>
											<el-col :span="4" class="un_col">
												<span class="un_span" v-if="testRecordDesc.updDate != undefined && testRecordDesc.updDate != ''">
													{{ testRecordDesc.updUserName}} {{testRecordDesc.updDate}}
												</span>
											</el-col>
											<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
											<!--新增和修改时按钮操作-->
											<el-col :span="1" v-if="testRecordDesc.recNo == '' || (updateFlg && testRecordDesc.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="saveDescHandle(testRecordDesc)"> 保存 </el-button>
											</el-col>
											<el-col :span="1" v-if="testRecordDesc.recNo == '' || (updateFlg && testRecordDesc.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="cancelDescHandle()"> 取消 </el-button>
											</el-col>
											<!--非新增和修改时按钮操作-->
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="updateDescHandle(testRecordDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
											</el-col>
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="deleteDescHandle(testRecordDesc.recNo)"> × 删除</el-button>
											</el-col>
										</el-col>
									</el-col>
								</draggable>
								<el-col :span="24" style="margin:10px">
									<el-button class="btn" type="primary" size="small" @click="addTestRecordDescHandle(index, index1)" :disabled="updateFlg || addFlg || basicVisible"> + 追加记录 </el-button>
								</el-col>
							</el-row>
						</div>
					</el-row>
				</div>
				<el-row>
					<div class="tr_title_row">
						4.2 现场巡检综述
					</div>
				</el-row>
				<div v-for="(inspectionSummary, index) in dataForm.inspectionSummaryList" :key="'inspection' + index">
					<el-row class="cp_row">
						<el-row class="cp_sub_row">
							{{inspectionSummary.subjectName}}
						</el-row>
						<div v-for="(inspection, index1) in inspectionSummary.inspectionList" :key="'inspection' + index1">
							<el-row class="cp_maj_row">
								{{inspection.majorName}}
							</el-row>
							<el-row>
								<el-table :row-class-name="tableRowClassName" ref="dataListTable" class="dataListTable" border
									:data="inspection.recordList" header-align="center" header-cell-class-name="dataListTHeader" 
									:cell-style="{ padding: '0px' }" style="margin:10px;width:97%">
									<el-table-column type="index" label="No." align="center" width="80">
									</el-table-column>
									<el-table-column prop="sutContent" label="巡检发现的问题及整改意见" align="left" show-overflow-tooltip>
									</el-table-column>
									<el-table-column prop="inspectionTime" label="提出时间" align="center" width="100">
									</el-table-column>
									<el-table-column prop="closeTime" label="封闭时间" align="center" width="100">
									</el-table-column>
									<el-table-column prop="closeMan" label="封闭人" align="center" width="150">
									</el-table-column>
								</el-table>
							</el-row>
							<el-row>
								<draggable v-model="inspection.inspectionDescList" @start="onDragStart('040200', index, index1, '')" @update="onDragEnd">
									<el-col :span="24" v-for="(inspectionDesc, index2) in inspection.inspectionDescList" :key="'inspection' + index2">
										<el-col :span="1">
											<i class="el-icon-position"></i>
										</el-col>
										<el-col :span="23" class="set_non" v-if="inspectionDesc.updUserName != undefined && inspectionDesc.updUserName != ''">
											<el-col :span="17" v-if="inspectionDesc.recNo == '' || (updateFlg && inspectionDesc.recNo == updateDescRecNo)">
												<el-input class="desc_input1" type="textarea" autosize  v-model="inspectionDesc.descContent"></el-input>
											</el-col>
											<el-col :span="17" class="desc_span" v-else>
												{{inspectionDesc.descContent}}
											</el-col>
											<el-col :span="4" class="un_col">
												<span class="un_span" v-if="inspectionDesc.updDate != undefined && inspectionDesc.updDate != ''">
													{{ inspectionDesc.updUserName}} {{inspectionDesc.updDate}}
												</span>
											</el-col>
											<!-- 占位 -->
											<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
											<!--新增和修改时按钮操作-->
											<el-col :span="1" v-if="inspectionDesc.recNo == '' || (updateFlg && inspectionDesc.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="saveDescHandle(inspectionDesc)"> 保存 </el-button>
											</el-col>
											<el-col :span="1" v-if="inspectionDesc.recNo == '' || (updateFlg && inspectionDesc.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="cancelDescHandle()"> 取消 </el-button>
											</el-col>
											<!--非新增和修改时按钮操作-->
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && inspectionDesc.showButton == 1">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="updateDescHandle(inspectionDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
											</el-col>
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && inspectionDesc.showButton == 1">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="deleteDescHandle(inspectionDesc.recNo)"> × 删除</el-button>
											</el-col>
										</el-col>
										<!-- 继承的数据添加背景色-->
										<el-col :span="23" class="set_bc" v-if="inspectionDesc.updUserName == undefined || inspectionDesc.updUserName == ''">
											<el-col :span="17" v-if="inspectionDesc.recNo == '' || (updateFlg && inspectionDesc.recNo == updateDescRecNo)">
												<el-input class="desc_input_bc" type="textarea" autosize v-model="inspectionDesc.descContent"></el-input>
											</el-col>
											<el-col :span="17" class="desc_span_color" v-else>
												{{inspectionDesc.descContent}}
											</el-col>
											<el-col :span="4" class="un_col">
												<span class="un_span" v-if="inspectionDesc.updDate != undefined && inspectionDesc.updDate != ''">
													{{ inspectionDesc.updUserName}} {{inspectionDesc.updDate}}
												</span>
											</el-col>
											<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
											<!--新增和修改时按钮操作-->
											<el-col :span="1" v-if="inspectionDesc.recNo == '' || (updateFlg && inspectionDesc.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="saveDescHandle(inspectionDesc)"> 保存 </el-button>
											</el-col>
											<el-col :span="1" v-if="inspectionDesc.recNo == '' || (updateFlg && inspectionDesc.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="cancelDescHandle()"> 取消 </el-button>
											</el-col>
											<!--非新增和修改时按钮操作-->
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="updateDescHandle(inspectionDesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
											</el-col>
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="deleteDescHandle(inspectionDesc.recNo)"> × 删除</el-button>
											</el-col>
										</el-col>
									</el-col>
								</draggable>
								<el-col :span="24" style="margin:10px">
									<el-button class="btn" type="primary" size="small" @click="addInspectionDescHandle(index, index1)" :disabled="updateFlg || addFlg || basicVisible"> + 追加记录 </el-button>
								</el-col>
							</el-row>
						</div>
					</el-row>
				</div>
				<el-row>
					<div class="tr_title_row">
						4.3 监理联系单、监理通知单处理及跟踪情况
					</div>
				</el-row>
				<el-row style="margin-top:10px">
					<el-col :span="12" class="contact_col">
						<el-form-item label-width="0">
							<span>截止本周末共下发</span>
							<span class="cv_span"> {{ dataForm.weeklyContact && dataForm.weeklyContact.issueContactNum }} </span>
							<span>份监理联系单，已整改完成 </span>
							<span class="cv_span"> {{ dataForm.weeklyContact &&  dataForm.weeklyContact.finishContactNum}} </span>
							<span>份。 </span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-table :row-class-name="tableRowClassName" ref="dataListTable" class="dataListTable" border
						:data="dataForm.weeklyContact && dataForm.weeklyContact.recordList" header-align="center" header-cell-class-name="dataListTHeader" 
						:cell-style="{ padding: '0px' }" style="margin-top:10px;width:100%">
						<el-table-column type="index" label="No." align="center" width="80">
						</el-table-column>
						<el-table-column prop="primaryContent" label="主要内容" align="center" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sendTime" label="签发时间" align="center" width="100">
						</el-table-column>
						<el-table-column prop="closeTime" label="封闭时间" align="center" width="100">
						</el-table-column>
						<el-table-column prop="handleOptions" label="处理情况" align="center" width="150">
						</el-table-column>
						<el-table-column prop="remark" label="备注" align="center" width="150">
						</el-table-column>
					</el-table>
				</el-row>
				<el-row style="margin-top:10px">
					<el-col :span="12" class="contact_col">
						<el-form-item label-width="0">
							<span>截止本周末共下发</span>
							<span class="cv_span"> {{ dataForm.weeklyNotice && dataForm.weeklyNotice.issueContactNum }} </span>
							<span>份监理通知单，已整改完成 </span>
							<span class="cv_span"> {{ dataForm.weeklyNotice && dataForm.weeklyNotice.finishContactNum}} </span>
							<span>份。 </span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-table :row-class-name="tableRowClassName" ref="dataListTable" class="dataListTable" border
						:data="dataForm.weeklyNotice && dataForm.weeklyNotice.recordList" header-align="center" header-cell-class-name="dataListTHeader" 
						:cell-style="{ padding: '0px' }" style="margin-top:10px;width:100%">
						<el-table-column type="index" label="No." align="center" width="80">
						</el-table-column>
						<el-table-column prop="primaryContent" label="主要内容" align="center" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sendTime" label="签发时间" align="center" width="100">
						</el-table-column>
						<el-table-column prop="closeTime" label="封闭时间" align="center" width="100">
						</el-table-column>
						<el-table-column prop="handleOptions" label="处理情况" align="center" width="150">
						</el-table-column>
						<el-table-column prop="remark" label="备注" align="center" width="150">
						</el-table-column>
					</el-table>
				</el-row>
				<div class="titleDiv">五、签收及审查文件情况</div>
				<el-row>
					<el-table :row-class-name="tableRowClassName" ref="dataListTable" class="dataListTable" border
						:data="dataForm.fileCheckRecords && dataForm.fileCheckRecords" header-align="center" header-cell-class-name="dataListTHeader" 
						:cell-style="{ padding: '0px' }" style="width:100%">
						<el-table-column type="index" label="No." align="center" width="80">
						</el-table-column>
						<el-table-column prop="fileName" label="文件名称" align="center" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="examineStatusName" label="状态" align="center" width="100">
						</el-table-column>
						<el-table-column prop="examineSuggestion" label="意见" align="center" show-overflow-tooltip>
						</el-table-column>
					</el-table>
				</el-row>
				<div class="titleDiv">六、会议与会谈情况</div>
				<div v-for="(meeting, index) in dataForm.meetingList" :key="'meet' + index">
					<el-row class="meet_row">
						<el-col :span="24">
							<div class="mc_div">
								{{meeting.fileName}}
							</div>
						</el-col>
						<div v-for="(photo, index1) in meeting.photoList" :key="'mp' + index1" >
							<el-col :span="4" style="margin-left:20px;">
								<el-image style="height: 220px;" :src="photo.fileUri" fit='contain'></el-image>
							</el-col>
						</div>
						<el-col :span="24">
							<div style="float: right;margin-right: 20px;margin-top:20px">
								<el-button type="text" class="button" style="margin-right: 10px;"
							 		@click="addOrUpdateMeeting(meeting.recNo)" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
								<el-button type="text" class="button"
									@click="deleteWeeklyReportFile(meeting.recNo, '060000')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
							</div>
						</el-col>
					</el-row>
				</div>
				<el-row style="margin-top:20px">
						<el-button class="mbtn" type="primary" size="small" @click="addOrUpdateMeeting()" :disabled="updateFlg || addFlg || basicVisible"> 添加会议与座谈内容 </el-button>
						<el-button class="mbtn" type="primary" size="small" @click="selectMeetingEnclosureFile('060000')" :disabled="updateFlg || addFlg || basicVisible"> 从监理会议模块提取 </el-button>
				</el-row>
				<div class="titleDiv">七、安全生产/HSE情况</div>
				<el-row>
					<draggable v-model="dataForm.hseRecordList" @start="onDragStart('070000'), '', '', ''" @update="onDragEnd">
						<el-col :span="24" v-for="(hseRecord, index) in dataForm.hseRecordList" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non" v-if="hseRecord.updUserName != undefined && hseRecord.updUserName != ''">
								<el-col :span="17" v-if="hseRecord.recNo == '' || (updateFlg && hseRecord.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize  v-model="hseRecord.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{hseRecord.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="hseRecord.updDate != undefined && hseRecord.updDate != ''">
										{{ hseRecord.updUserName}} {{hseRecord.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="hseRecord.recNo == '' || (updateFlg && hseRecord.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(hseRecord)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="hseRecord.recNo == '' || (updateFlg && hseRecord.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && hseRecord.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(hseRecord.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && hseRecord.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(hseRecord.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
							<!-- 继承的数据添加背景色-->
							<el-col :span="23" class="set_bc" v-if="hseRecord.updUserName == undefined || hseRecord.updUserName == ''">
								<el-col :span="17" v-if="hseRecord.recNo == '' || (updateFlg && hseRecord.recNo == updateDescRecNo)">
									<el-input class="desc_input_bc" type="textarea" autosize v-model="hseRecord.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span_color" v-else>
									{{hseRecord.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="hseRecord.updDate != undefined && hseRecord.updDate != ''">
										{{ hseRecord.updUserName}} {{hseRecord.updDate}}
									</span>
								</el-col>
								<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="hseRecord.recNo == '' || (updateFlg && hseRecord.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(hseRecord)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="hseRecord.recNo == '' || (updateFlg && hseRecord.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(hseRecord.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(hseRecord.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-top:10px">
						<el-button class="btn" type="primary" size="small" @click="addHseHandle()"  :disabled="updateFlg || addFlg || basicVisible"> + 追加记录 </el-button>
					</el-col>
				</el-row>
				<div class="titleDiv">八、监理建议或意见</div>
				<el-row>
					<draggable v-model="dataForm.opinionList" @start="onDragStart('080000'), '', '', ''" @update="onDragEnd">
						<el-col :span="24" v-for="(opinion, index) in dataForm.opinionList" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non" v-if="opinion.updUserName != undefined && opinion.updUserName != ''">
								<el-col :span="17" v-if="opinion.recNo == '' || (updateFlg && opinion.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize  v-model="opinion.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{opinion.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="opinion.updDate != undefined && opinion.updDate != ''">
										{{ opinion.updUserName}} {{opinion.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="opinion.recNo == '' || (updateFlg && opinion.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(opinion)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="opinion.recNo == '' || (updateFlg && opinion.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && opinion.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(opinion.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && opinion.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(opinion.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
							<!-- 继承的数据添加背景色-->
							<el-col :span="23" class="set_bc" v-if="opinion.updUserName == undefined || opinion.updUserName == ''">
								<el-col :span="17" v-if="opinion.recNo == '' || (updateFlg && opinion.recNo == updateDescRecNo)">
									<el-input class="desc_input_bc" type="textarea" autosize v-model="opinion.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span_color" v-else>
									{{opinion.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="opinion.updDate != undefined && opinion.updDate != ''">
										{{ opinion.updUserName}} {{opinion.updDate}}
									</span>
								</el-col>
								<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="opinion.recNo == '' || (updateFlg && opinion.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(opinion)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="opinion.recNo == '' || (updateFlg && opinion.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(opinion.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(opinion.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-top:10px">
						<el-button class="btn" type="primary" size="small" @click="addOpinionHandle()" :disabled="updateFlg || addFlg || basicVisible"> + 追加记录 </el-button>
					</el-col>
				</el-row>
				<div class="titleDiv">九、本周附件（随周报发送的附件）</div>
				<el-row>
						<el-upload class="addFileBox" ref="uploadEnclosureFile" :action="action"
						  :http-request="uploadEnclosureFile" style=" display: inline-block;margin-right:10px">
						 	<el-button class="btn" type="primary" size="small" :disabled="updateFlg || addFlg || basicVisible"> 添加本地附件 </el-button>
						</el-upload>
						<el-button class="obtn" type="primary" size="small" @click="selectContactEnclosureFile()" :disabled="updateFlg || addFlg || basicVisible"> 添加联系单 </el-button>
						<el-button class="obtn" type="primary" size="small" @click="selectSpecialReportEnclosureFile()" :disabled="updateFlg || addFlg || basicVisible"> 添加专题报告 </el-button>
						<el-button class="obtn" type="primary" size="small" @click="selectMeetingEnclosureFile('090000')" :disabled="updateFlg || addFlg || basicVisible"> 添加会议纪要 </el-button>
				</el-row>
				<el-row>
					<el-table ref="singleTable" class="dataListTable" :data="dataForm.enclosureFileList" header-align="center" :show-header=false 
							empty-text="无附件" header-cell-class-name="dataListTHeader" border :cell-style="{ padding: '0px' }" 
							row-key="recNo" style="width:100%;margin-top:10px">
						<el-table-column type="index" label="No." align="center" width="80">
						</el-table-column>
						<el-table-column prop="fileName" label="" align="center" show-overflow-tooltip>
						</el-table-column>
						<el-table-column width="200" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="viewEnclosureFile(scope.row.filePathUri, scope.row.fileName)" :disabled="updateFlg || addFlg || basicVisible"> 查看 </el-button>
								<el-button type="text" @click="deleteWeeklyReportFile(scope.row.recNo, '090000')" :disabled="updateFlg || addFlg || basicVisible"> 刪除 </el-button>
							</template>	
						</el-table-column>
					</el-table>
				</el-row>
				<div class="titleDiv">十、现场照片</div>
				<el-row>
					<div class="tr_qp_row" style="margin-bottom:-10px">进度照片</div>
				</el-row>
				<div v-for="(progressPhoto, index) in dataForm.progressPhotoList" :key="'pp' + index">
					<el-row class="cp_row">
						<el-row class="cp_sub_row">
							{{progressPhoto.subjectName}}
						</el-row>
					</el-row>
					<el-row>
						<el-button class="btn" type="primary" size="small" @click="selectqpPhoto('2', index)" :disabled="updateFlg || addFlg || basicVisible"> 选择照片 </el-button>
					</el-row>
					<el-row class="photo_row">
						<el-col :span="23" style="width:90%;margin-left:170px">
							<div v-for="(photo, index1) in progressPhoto.photoList" :key="'pp' + index1" v-if="progressPhoto.photoList != undefined && progressPhoto.photoList.length > 0"
								:style="{ order: photo.sort }" @dragstart="dragStart(index, index1)" @dragover.prevent
								@drop="drop(index, index1, '1')" @dragend="dragEnd(index, '1')" draggable="true">
								<el-col :span="9" class="photo_col">
									<div style="margin:20px 20px 20px 20px ">
										<el-image style="height: 250px;" :src="photo.fileUri" fit='contain'></el-image>
										<div class="t-column-ellipsis">
											{{photo.fileName}}
										</div>
										<div style="float:right">
											<el-button type="text" class="button" style="margin-right: 10px;"
												@click="updateImgContent(photo.recNo, photo.fileName)" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
											<el-button type="text" class="button"
												@click="deleteWeeklyReportFile(photo.recNo, '100000')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
										</div>
									</div>
								</el-col>
							</div>
						</el-col>
					</el-row>
				</div>
				<el-row>
					<div class="tr_qp_row" style="margin-top:10px;margin-bottom:-10px">质量照片</div>
				</el-row>
				<div v-for="(qualityPhoto, index) in dataForm.qualityPhotoList" :key="'qp' + index">
					<el-row class="cp_row">
						<el-row class="cp_sub_row">
							{{qualityPhoto.subjectName}}
						</el-row>
					</el-row>
					<el-row>
						<el-button class="btn" type="primary" size="small" @click="selectqpPhoto('1', index)" :disabled="updateFlg || addFlg || basicVisible"> 选择照片 </el-button>
					</el-row>
					<el-row class="photo_row">
						<el-col :span="23" style="width:90%;margin-left:170px">
							<div v-for="(photo, index1) in qualityPhoto.photoList" :key="'qp' + index1" v-if="qualityPhoto.photoList != undefined && qualityPhoto.photoList.length > 0"
								:style="{ order: photo.sort }" @dragstart="dragStart(index, index1)" @dragover.prevent
								@drop="drop(index, index1, '2')" @dragend="dragEnd(index, '2')" draggable="true">
								<el-col :span="9" class="photo_col">
									<div style="margin:20px 20px 20px 20px ">
										<el-image style="height: 250px;" :src="photo.fileUri" fit='contain'></el-image>
										<div class="t-column-ellipsis">
											{{photo.fileName}}
										</div>
										<div style="float:right">
											<el-button type="text" class="button" style="margin-right: 10px;"
												@click="updateImgContent(photo.recNo, photo.fileName)" :disabled="updateFlg || addFlg || basicVisible"><i class="el-icon-edit"></i>修改</el-button>
											<el-button type="text" class="button"
												@click="deleteWeeklyReportFile(photo.recNo, '100000')" :disabled="updateFlg || addFlg || basicVisible">× 删除</el-button>
										</div>
									</div>
								</el-col>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-card>
        </el-form>

		<!-- 质量进度照片内容修改 -->
		<el-dialog title="修改描述" :visible.sync="updateImgContentVisable" width="30%" :close-on-click-modal="false">
			<el-form>
				<el-row>
					<el-col :span="24">
						<el-form-item label="">
							<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" v-model="updatePhotoContent"
								placeholder="请填写" style="width:420px"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="saveImgContent">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="updateImgContentVisable = false">取 消</el-button>
			</span>
		</el-dialog>

		<contact-select v-if="contactSelectVisible" ref="contactSelect" @selectContactItems="margeSelectContactItems"></contact-select>
		<special-report-select v-if="specialReportSelectVisible " ref="specialReportSelect" @selectSpecialReportItems="margeSelectSpecialReportItems"></special-report-select> 
		<meeting-select v-if="meetingSelectVisible " ref="meetingSelect" @selectMeetingItems="margeSelectMeetingItems"></meeting-select> 
		<qp-photo-select v-if="selectQPPhotoVisible" ref="qpPhotoSelect" @selectPhotoItems="margeSelectPhotoItems"></qp-photo-select>
		<add-or-update-meeting v-if="addOrUpdateMeetingVisible" ref="addOrUpdateMeeting"  @refreshDataList="getWeeklyMeetingList"></add-or-update-meeting>
		<add-or-update-situation v-if="addOrUpdateSituationVisible" ref="addOrUpdateSituation"  @refreshDataList="getWeeklySituationList"></add-or-update-situation>
	</div>
</template>
<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js";
import ContactSelect from './contact-select.vue'
import SpecialReportSelect from './special-report-select .vue'
import MeetingSelect from './meeting-select.vue'
import QpPhotoSelect from './qp-photo-select.vue'
import AddOrUpdateMeeting from './add-or-update-meeting.vue'
import AddOrUpdateSituation from './add-or-update-situation.vue'
import Sortable from "sortablejs";
import draggable from 'vuedraggable';

export default {
	data() {
		return {
			basicVisible: false,
			addOrUpdateVisible: false,
			contactSelectVisible: false,
			specialReportSelectVisible: false,
			meetingSelectVisible: false,
			selectQPPhotoVisible: false,
			photoFlag: 0,
			dialogVisible: false,
			meetPhotoDialogVisible: false,
			meetPhotoListIndex: 0,
			addImg: addImg,
			recNo: '',
			action: '',
			photoList: [],
			meetDescList: [],
			meetPhotoList: [],
			enclosureFileList: [],
			projectDesc: [],
			majorList: [],
			subjectList: {},
			photoPojectNo: '',
			subjectNo: '',
			photoContent: '',
			meetPhotoContent: '',
			workNo: $common.getItem("workNo"),
			dataRule: {},
			dataForm: {
				projectInfo: {
					projectName: ''
				},
				designDrawing: {
					fileList: [],
					progressDescList: []
				},
				equipmentDataReview: {
					fileList: [],
					progressDescList: []
				},
				productionDesign: {
					fileList: [],
					progressDescList: []
				},
				procurementProgress: {
					fileList: [],
					progressDescList: []
				},
				onsiteProductionProgress: {
					fileList: [],
					progressDescList: []
				}
			},
			photoViewCol: 6,
			draggedItem: {},
			draggedPhotoItem: {},
			selectPhotoType: '',
			recNos: [],
			updateImgContentVisable: false,
			addOrUpdateMeetingVisible: false,
			addOrUpdateSituationVisible: false,
			photoType: '',
			photoRecNo: '',
			updatePhotoContent: '',
			contentIdx: '',
			menuId: 'basic',
			nodeList: [],
			headers:[],
			addFlg: false,
			updateFlg: false,
			updateDescRecNo: '',
			qpPhotoIndex: '',
			selectPhotoType: '',
			meetDataType: '',
			updateDataType: '',
			updateIndex: '',
			updateIndex1: '',
			updateIndex2: ''
		};
	},
	components: {
		ContactSelect,
		SpecialReportSelect,
		MeetingSelect,
		QpPhotoSelect,
		AddOrUpdateMeeting,
		AddOrUpdateSituation,
		draggable
	},
	mounted() {
		this.recNo = this.$route.query.recNo;
		this.getProjectWeeklyReportInfo()
		this.dragSort();
	},
	methods: {
		updateBasicHandle() {
			this.basicVisible = true;
		},
		saveBasicHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/update",
				method: 'post',
				data: this.$http.adornData({
					recNo: this.recNo,
					weeklyName: this.dataForm.weeklyName,
					reportMan: this.dataForm.reportMan,
					reportTime: this.dataForm.reportTime,
					refirmMan: this.dataForm.refirmMan,
					refirmTime: this.dataForm.refirmTime,
					productProgressStatus:this.dataForm.productProgressStatus,
					overallProgressStatus:this.dataForm.overallProgressStatus,
					weekQualityStatus:this.dataForm.weekQualityStatus,
					weekSafeStatus:this.dataForm.weekSafeStatus,
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.getProjectWeeklyReportInfo()
					this.basicVisible = false;
				}
			})
		},
		getProjectWeeklyReportInfo() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/getProjectWeeklyReportInfo/" + this.recNo,
				method: "get",
				params: {
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					// 主要建造大节点
					this.nodeList = data.body.nodeInfo.records;
					this.headers = data.body.nodeInfo.headers;
					// 重新设置附件名称
					let enclosureFiles = []
                    if(data.body.enclosureFileList != undefined && data.body.enclosureFileList.length > 0) {
                        for(var i = 0; i < data.body.enclosureFileList.length; i++) {
                            data.body.enclosureFileList[i].fileName = '附件' + (i + 1 ) + "：" + data.body.enclosureFileList[i].fileName
                            enclosureFiles.push(data.body.enclosureFileList[i]);
                        }
                    }
                    this.dataForm.enclosureFileList = enclosureFiles;

					// 将header名称列改为工程编号
					this.headers[0].nodeName = '工程编号'
					this.addFlg = false;
					this.updateFlg = false;
					this.updateDescRecNo= '';
				}
			});
		},
		getNodeInfoList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectprogressnoderecord/list/" + $common.getItem("workNo"),
				method: 'get',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
				this.nodeList = data.body.records;
				this.headers = data.body.headers;
				// 将header名称列改为工程编号
				this.headers[0].nodeName = '工程编号'
				}
			})
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableScnenRowClassName({ row, rowIndex });
		},
		flitterSubjectName (arr) {
			let spanOneArr = []
			let concatOne = 0
			arr.forEach((item, index) => {
				if (index === 0) {
				spanOneArr.push(1);
				} else {
				//name 修改
				if (item.subjectName === arr[index - 1].subjectName) { //第一列需合并相同内容的判断条件
					spanOneArr[concatOne] += 1;
					spanOneArr.push(0);
				} else {
					spanOneArr.push(1);
					concatOne = index;
				};
				}
			});
			return {
				one: spanOneArr,
			}
		},
		flitterData (arr) {
			let spanOneArr = []
			let concatOne = 0
			arr.forEach((item, index) => {
				if (index === 0) {
					spanOneArr.push(1);
				} else {
				//name 修改
				if (item.title === arr[index - 1].title) { //第一列需合并相同内容的判断条件
					spanOneArr[concatOne] += 1;
					spanOneArr.push(0);
				} else {
					spanOneArr.push(1);
					concatOne = index;
				};
				}
			});
			return {
				one: spanOneArr,
			}
		},
		// 建造大节点，合并行
		objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				// this.tableData  修改
				const _row = (this.flitterSubjectName(this.nodeList).one)[rowIndex];
				const _col = _row > 0 ? 1 : 0;
				return {
				rowspan: _row,
				colspan: _col
				};
			} else if(columnIndex === 1){
				// this.tableData  修改
				const _row = (this.flitterData(this.nodeList).one)[rowIndex];
				const _col = _row > 0 ? 1 : 0;
				return {
				rowspan: _row,
				colspan: _col
				};
			}
		},
		objectTestRecordMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				// this.tableData  修改
				const _row = (this.flitterSubjectNo(this.dataForm.testItemRecords).one)[rowIndex];
				const _col = _row > 0 ? 1 : 0;
				return {
				rowspan: _row,
				colspan: _col
				};
			} 
		},
		flitterSubjectNo (arr) {
			let spanOneArr = []
			let concatOne = 0
			arr.forEach((item, index) => {
				if (index === 0) {
				spanOneArr.push(1);
				} else {
				//name 修改
				if (item.subjectNo=== arr[index - 1].subjectNo) { //第一列需合并相同内容的判断条件
					spanOneArr[concatOne] += 1;
					spanOneArr.push(0);
				} else {
					spanOneArr.push(1);
					concatOne = index;
				};
				}
			});
			return {
				one: spanOneArr,
			}
		},
		addMainEventHandle() {
			// 计算最大排序值
			var sort = 0;
			if(this.dataForm.mainEventDescList != undefined && this.dataForm.mainEventDescList.length > 0) {
				for(var i = 0 ; i < this.dataForm.mainEventDescList.length; i++ ) {
					if(sort < this.dataForm.mainEventDescList[i].sort) {
						sort = this.dataForm.mainEventDescList[i].sort
					}
				}
			}
			let maineventDesc = {
				 recNo: '',
				 dataType : '020000',
				 weeklyRecNo: this.recNo,
				 subjectNo: '',
				 major: '',
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.mainEventDescList.push(maineventDesc)
			this.addFlg = true;
		}, 
		updateDescHandle(descRecNo) {
			this.updateFlg = true,
			this.updateDescRecNo = descRecNo
		},
		saveDescHandle(reportDesc) {
			let _url = "/business/projectweeklyreportdesc/save";
			if (reportDesc.recNo) {
				reportDesc.updUserName = $common.getItem("userName")
				_url = "/business/projectweeklyreportdesc/update";
			}
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(reportDesc)
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.getProjectWeeklyReportInfo();
						}
					});
				}
			});
		},
		cancelDescHandle() {
			this.getProjectWeeklyReportInfo();
		},
		deleteDescHandle(descRecNo) {
			// 直接db修改
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectweeklyreportdesc/delete/" + descRecNo,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getProjectWeeklyReportInfo();
							}
						})
					} 
				});
			})
		},
		getCurrentDate() {
			//获取当前时间并打印
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			if(mm< 10) {
				mm = '0' + mm;
			}
			return yy + '.' + mm + '.' + dd;
		},
		addConstructionProgressHandle(index, index1, index2) {
			let subjectNo = this.dataForm.constructionProgressList[index].subjectNo
			let major = this.dataForm.constructionProgressList[index].conProgressList[index1].major
			let subcategory = this.dataForm.constructionProgressList[index].conProgressList[index1].progressList[index2].subcategory
			// 计算最大排序值
			let sort = 0;
			let progressDescList = this.dataForm.constructionProgressList[index].conProgressList[index1].progressList[index2].progressDescList;
			if(progressDescList != undefined && progressDescList.length > 0) {
				for(var i = 0; i < progressDescList.length; i++) {
					if(progressDescList[i].sort > sort) {
						sort = progressDescList[i].sort
					}
				}
			}

			let constructionProgress = {
				 recNo: '',
				 dataType : '030000',
				 weeklyRecNo: this.recNo,
				 subjectNo: subjectNo,
				 major: major,
				 subcategory: subcategory,
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.constructionProgressList[index].conProgressList[index1].progressList[index2].progressDescList.push(constructionProgress)
			this.addFlg = true;
		},
		addDesignDrawingHandle() {
			// 计算最大排序值
			let sort = 0;
			let progressDescList = this.dataForm.designDrawing.progressDescList;
			if(progressDescList != undefined && progressDescList.length > 0) {
				for(var i = 0; i < progressDescList.length; i++) {
					if(progressDescList[i].sort > sort) {
						sort = progressDescList[i].sort
					}
				}
			}
			let drawing = {
				 recNo: '',
				 dataType : '030101',
				 weeklyRecNo: this.recNo,
				 subjectNo: '',
				 major: '',
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.designDrawing.progressDescList.push(drawing)
			this.addFlg = true;
		},
		addEquipmentDataReviewHandle() {
			// 计算最大排序值
			let sort = 0;
			let progressDescList = this.dataForm.equipmentDataReview.progressDescList;
			if(progressDescList != undefined && progressDescList.length > 0) {
				for(var i = 0; i < progressDescList.length; i++) {
					if(progressDescList[i].sort > sort) {
						sort = progressDescList[i].sort
					}
				}
			}
			let review = {
				 recNo: '',
				 dataType : '030102',
				 weeklyRecNo: this.recNo,
				 subjectNo: '',
				 major: '',
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.equipmentDataReview.progressDescList.push(review)
			this.addFlg = true;
		},
		addProductionDesignHandle() {
			// 计算最大排序值
			let sort = 0;
			let progressDescList = this.dataForm.productionDesign.progressDescList;
			if(progressDescList != undefined && progressDescList.length > 0) {
				for(var i = 0; i < progressDescList.length; i++) {
					if(progressDescList[i].sort > sort) {
						sort = progressDescList[i].sort
					}
				}
			}
			let design = {
				 recNo: '',
				 dataType : '030103',
				 weeklyRecNo: this.recNo,
				 subjectNo: '',
				 major: '',
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.productionDesign.progressDescList.push(design)
			this.addFlg = true;
		},
		addProcurementProgressHandle() {
			// 计算最大排序值
			let sort = 0;
			let progressDescList = this.dataForm.procurementProgress.progressDescList;
			if(progressDescList != undefined && progressDescList.length > 0) {
				for(var i = 0; i < progressDescList.length; i++) {
					if(progressDescList[i].sort > sort) {
						sort = progressDescList[i].sort
					}
				}
			}
			let progress = {
				 recNo: '',
				 dataType : '030200',
				 weeklyRecNo: this.recNo,
				 subjectNo: '',
				 major: '',
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.procurementProgress.progressDescList.push(progress)
			this.addFlg = true;
		},
		addOnsiteProductionProgressHandle() {
			// 计算最大排序值
			let sort = 0;
			let progressDescList = this.dataForm.onsiteProductionProgress.progressDescList;
			if(progressDescList != undefined && progressDescList.length > 0) {
				for(var i = 0; i < progressDescList.length; i++) {
					if(progressDescList[i].sort > sort) {
						sort = progressDescList[i].sort
					}
				}
			}
			let progress = {
				 recNo: '',
				 dataType : '030300',
				 weeklyRecNo: this.recNo,
				 subjectNo: '',
				 major: '',
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.onsiteProductionProgress.progressDescList.push(progress)
			this.addFlg = true;
		},
		addTestRecordDescHandle(index, index1) {
			let subjectNo = this.dataForm.testRecordSummaryList[index].subjectNo
			let major = this.dataForm.testRecordSummaryList[index].testRecordList[index1].major

			// 计算最大排序值
			let sort = 0;
			let testRecordDescList = this.dataForm.testRecordSummaryList[index].testRecordList[index1].testRecordDescList;
			if(testRecordDescList != undefined && testRecordDescList.length > 0) {
				for(var i = 0; i < testRecordDescList.length; i++) {
					if(testRecordDescList[i].sort > sort) {
						sort = testRecordDescList[i].sort
					}
				}
			}

			let testRecordDesc = {
				 recNo: '',
				 dataType : '040100',
				 weeklyRecNo: this.recNo,
				 subjectNo: subjectNo,
				 major: major,
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}

			this.dataForm.testRecordSummaryList[index].testRecordList[index1].testRecordDescList.push(testRecordDesc)
			this.addFlg = true;
		},
		addInspectionDescHandle(index, index1){
			let subjectNo = this.dataForm.inspectionSummaryList[index].subjectNo
			let major = this.dataForm.inspectionSummaryList[index].inspectionList[index1].major

			// 计算最大排序值
			let sort = 0;
			let inspectionDescList = this.dataForm.inspectionSummaryList[index].inspectionList[index1].inspectionDescList;
			if(inspectionDescList != undefined && inspectionDescList.length > 0) {
				for(var i = 0; i < inspectionDescList.length; i++) {
					if(inspectionDescList[i].sort > sort) {
						sort = inspectionDescList[i].sort
					}
				}
			}

			let inspectionDesc = {
				 recNo: '',
				 dataType : '040200',
				 weeklyRecNo: this.recNo,
				 subjectNo: subjectNo,
				 major: major,
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}

			this.dataForm.inspectionSummaryList[index].inspectionList[index1].inspectionDescList.push(inspectionDesc)
			this.addFlg = true;
		},
		addHseHandle() {
			// 计算最大排序值
			var sort = 0;
			if(this.dataForm.hseRecordList != undefined && this.dataForm.hseRecordList.length > 0) {
				for(var i = 0 ; i < this.dataForm.hseRecordList.length; i++ ) {
					if(sort < this.dataForm.hseRecordList[i].sort) {
						sort = this.dataForm.hseRecordList[i].sort
					}
				}
			}
			let hseRecord = {
				 recNo: '',
				 dataType : '070000',
				 weeklyRecNo: this.recNo,
				 subjectNo: '',
				 major: '',
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.hseRecordList.push(hseRecord)
			this.addFlg = true;
		},
		addOpinionHandle() {
			// 计算最大排序值
			var sort = 0;
			if(this.dataForm.opinionList != undefined && this.dataForm.opinionList.length > 0) {
				for(var i = 0 ; i < this.dataForm.opinionList.length; i++ ) {
					if(sort < this.dataForm.opinionList[i].sort) {
						sort = this.dataForm.opinionList[i].sort
					}
				}
			}
			let opinion = {
				 recNo: '',
				 dataType : '080000',
				 weeklyRecNo: this.recNo,
				 subjectNo: '',
				 major: '',
				 subcategory: '',
				 sort: sort + 1,
				 descContent: '',
				 updUserName: $common.getItem("userName"),
				 updDate: this.getCurrentDate()
			}
			this.dataForm.opinionList.push(opinion)
			this.addFlg = true;
		},
		uploadConstructionProgressPhotos(param) {
			let index = param.data.index;
			let subjectNo = param.data.subjectNo;
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadConstructionProgressPhotos[index].clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/94/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					// 计算最大排序值
					let sort = 0;
					let fileList = this.dataForm.constructionProgressList[index].fileList
					if(fileList != undefined && fileList.length > 0) {
						for(var i = 0; i < fileList.length; i++) {
							if(fileList[i].sort > sort) {
								sort = fileList[i].sort
							}
						}
					}

					this.dataForm.constructionProgressList[index].fileList.push({
						filePath: res.body.fileSavePath,
						fileUri: res.body.fileUri,
						fileName: this.extractFileName(res.body.fileName),
						sort: sort + 1,
					});

					let weeklyFileList = []
					// 保存到db中
					let weeklyFile = {
						weeklyRecNo: this.recNo,
						dataType: "030000",
						subjectNo: subjectNo,
						sort: sort + 1,
						fileName: this.extractFileName(res.body.fileName),
						filePath: res.body.fileSavePath,
						sourceType: '',
						sourceId: ''
					}
					weeklyFileList.push(weeklyFile)
					this.saveWeeklyFileList(weeklyFileList, '030000')
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		uploadSituationPhotos(param) {
			let dataType = param.data.type;
			const formData = new FormData();
			formData.append('file', param.file);
			if(dataType == '030101') {
				this.$refs.designDrawingPhotos.clearFiles();
			} else if (dataType == '030102') {
				this.$refs.equipmentDataReviewPhotos.clearFiles();
			} else if (dataType == '030103') {
				this.$refs.productionDesignPhotos.clearFiles();
			} else if (dataType == '030200') {
				this.$refs.procurementProgressPhotos.clearFiles();
			} else if (dataType == '030300') {
				this.$refs.onsiteProductionProgressPhotos.clearFiles();
			}
			// this.$refs.uploadConstructionProgressPhotos[index].clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					let photoList = []
					if(dataType == '030101') {
						photoList = this.dataForm.designDrawing.fileList;
					} else if (dataType == '030102') {
						photoList = this.dataForm.equipmentDataReview.fileList;
					} else if (dataType == '030103') {
						photoList = this.dataForm.productionDesign.fileList;
					} else if (dataType == '030200') {
						photoList = this.dataForm.procurementProgress.fileList;
					} else if (dataType === '030300') {
						photoList = this.dataForm.onsiteProductionProgress.fileList;
					}

					// 计算最大排序值
					let sort = 1;
					if(photoList != undefined && photoList.length > 0) {
						for(var i = 0; i < photoList.length; i++) {
							if(photoList[i].sort > sort) {
								sort = photoList[i].sort
							}
						}
					}

					// this.dataForm.constructionProgressList[index].fileList.push({
					// 	filePath: res.body.fileSavePath,
					// 	fileUri: res.body.fileUri,
					// 	fileName: this.extractFileName(res.body.fileName),
					// 	sort: sort + 1,
					// });

					let weeklyFileList = []
					// 保存到db中
					let weeklyFile = {
						weeklyRecNo: this.recNo,
						dataType: dataType,
						subjectNo: '',
						sort: sort + 1,
						fileName: this.extractFileName(res.body.fileName),
						filePath: res.body.fileSavePath,
						sourceType: '',
						sourceId: ''
					}
					weeklyFileList.push(weeklyFile)
					this.saveWeeklyFileList(weeklyFileList, dataType)
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		extractFileName(fileName) {
            let index = fileName.lastIndexOf(".");
            return fileName.substr(0, index)
        },
		saveWeeklyFileList(weekFileList, dataType) {
			let _url = "/business/projectweeklyreportfile/saveBatch";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					records: weekFileList
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if(dataType === '030000') {
						this.getConstructionProgressList();
					} else if(dataType === '090000') {
						this.getEnclosureFileList();
					} else if(dataType === '100000') {
						this.getScenePhotoList()
					} else if(dataType === '030101' || dataType === '030102' || dataType === '030103' || dataType == '030200' || dataType == '030300') {
						this.getWeeklySituationList()
					}
				}
			});
		},
		getWeeklySituationList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/getWeeklySituationList/" + this.recNo,
				method: "get",
				params: {
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.designDrawingList = data.body.designDrawingList
					this.dataForm.equipmentDataReviewList = data.body.equipmentDataReviewList
					this.dataForm.productionDesignList = data.body.productionDesignList
					this.dataForm.procurementProgressList = data.body.procurementProgressList
					this.dataForm.onsiteProductionProgressList = data.body.onsiteProductionProgressList
				}
			});
		},
		saveMeetingFiles(meetIds) {
			// 计算最大排序值
			let sort = 0;
			let meetingList = this.dataForm.meetingList
			if(meetingList != undefined && meetingList.length > 0) {
				for(var i = 0; i < meetingList.length; i++) {
					if(meetingList[i].sort > sort) {
						sort = meetingList[i].sort
					}
				}
			}
			let _url = "/business/projectweeklyreportfile/saveMeetingFiles";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					weeklyRecNo: this.recNo,
					meetIds: meetIds,
					sort: sort + 1
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.getWeeklyMeetingList();
				}
			});
		},
		getWeeklyMeetingList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/getWeeklyMeetingList/" + this.recNo,
				method: "get",
				params: {
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.meetingList = data.body
				}
			});
		},
		getScenePhotoList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/getScenePhotoList/" + this.recNo,
				method: "get",
				params: {
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.progressPhotoList = data.body.progressPhotoList
					this.dataForm.qualityPhotoList = data.body.qualityPhotoList
				}
			});
		},
		getConstructionProgressList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/getConstructionProgressList/" + this.recNo,
				method: "get",
				params: {
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.constructionProgressList = data.body
				}
			});
		},
		dragStart(index, index1) {
            this.draggedItem = { index: index, index1: index1 };
        },
        drop(index, index1, photoType) {
            const x = this.draggedItem.index1
            const y = index1
			let fileList = []
			if(photoType === '1') {
				// 进度照片
				fileList = this.dataForm.progressPhotoList[index].photoList
			} else if(photoType === '2') {
				// 质量照片
				fileList = this.dataForm.qualityPhotoList[index].photoList
			} else if(photoType === '3'){
				// 建造进度综述
 				fileList = this.dataForm.constructionProgressList[index].fileList
			} else if(photoType === '030101') {
				// 详细设计图纸审查进度情况
				fileList = this.dataForm.designDrawing.fileList
			} else if(photoType === '030102') {
				// 设备资料审查进度情况
				fileList = this.dataForm.equipmentDataReview.fileList
			} else if(photoType === '030103') {
				// 生产设计进展情况
				fileList = this.dataForm.productionDesign.fileList
			} else if(photoType === '030200') {
				// 生产设计进展情况
				fileList = this.dataForm.procurementProgress.fileList
			} else if(photoType === '030300') {
				// 生产设计进展情况
				fileList = this.dataForm.onsiteProductionProgress.fileList
			}
            
            if (x == y) {
                return
            } else if (x < y) {
                const item = fileList.splice(x, 1)[0];
                fileList.splice(y, 0, item);
            } else {
                const item = fileList.splice(x, 1)[0];
                fileList.splice(y, 0, item);
            }
        },
        dragEnd(index, photoType) {
            this.draggedItem = {};
			// 保存至db
			let fileList = []
			let subjectNo= ''
			let dataType = ''
			if(photoType === '1') {
				// 进度照片
				fileList = this.dataForm.progressPhotoList[index].photoList
				subjectNo = this.dataForm.progressPhotoList[index].subjectNo
				dataType = '100100'
			} else if(photoType === '2') {
				// 质量照片
				fileList = this.dataForm.qualityPhotoList[index].photoList
				subjectNo = this.dataForm.qualityPhotoList[index].subjectNo
				dataType = '100200'
			} else if (photoType === '3'){
				// 建造进度综述
 				fileList = this.dataForm.constructionProgressList[index].fileList
				subjectNo = this.dataForm.constructionProgressList[index].subjectNo
				dataType = '030000'
			} else if (photoType === '030101') {
				// 详细设计图纸审查进度情况
				fileList = this.dataForm.designDrawing.fileList
				dataType = '030101'
			} else if (photoType === '030102') {
				// 设备资料审查进度情况
				fileList = this.dataForm.equipmentDataReview.fileList
				dataType = '030102'
			} else if (photoType === '030103') {
				// 生产设计进展情况
				fileList = this.dataForm.productionDesign.fileList
				dataType = '030103'
			} else if (photoType === '030200') {
				// 采购进度情况
				fileList = this.dataForm.procurementProgress.fileList
				dataType = '030200'
			} else if (photoType === '030300') {
				// 现场生产进度情况
				fileList = this.dataForm.onsiteProductionProgress.fileList
				dataType = '030300'
			}

			let reportFile = {
				fileList: fileList,
				weeklyRecNo: this.recNo,
				subjectNo: subjectNo,
				dataType: dataType
			}
			this.weeklyReportSort(reportFile, dataType)
			
        },
		weeklyReportSort(reportFile, dataType) {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportfile/sort",
				method: "POST",
				data: this.$http.adornData({
					fileList: reportFile.fileList,
					weeklyRecNo: this.recNo,
					subjectNo: reportFile.subjectNo,
					dataType: reportFile.dataType
				}),
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							if(dataType === '030000') {
								this.getConstructionProgressList()
							} else if(dataType === '090000') {
								this.getEnclosureFileList()
							} else if(dataType === '030000'){
								this.getScenePhotoList()
							} else if(dataType === '030101' || dataType === '030102' || dataType === '030103' || dataType == '030200' || dataType == '030300') {
								this.getWeeklySituationList()
							}
						}
					})
				}
			});
		},
		deleteWeeklyReportFile(recNo, dataType) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectweeklyreportfile/delete/" + recNo,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								if (dataType === '030000') {
									this.getConstructionProgressList();
								} else if (dataType === '060000') {
									this.getWeeklyMeetingList();
								} else if (dataType === '090000') {
									this.getEnclosureFileList();
								} else if (dataType === '100000') {
									this.getScenePhotoList()
								} else if(dataType === '030101' || dataType === '030102' || dataType === '030103'|| dataType === '030200' || dataType === '030300' ) {
									this.getWeeklySituationList()
								}
							}
						})
					} 
				});
			})
		},
		addOrUpdateMeeting(recNo) {
			// 计算最大排序值
			let sort = 0;
			let meetingList = this.dataForm.meetingList
			if(meetingList != undefined && meetingList.length > 0) {
				for(var i = 0; i < meetingList.length; i++) {
					if(meetingList[i].sort > sort) {
						sort = meetingList[i].sort
					}
				}
			}
			this.addOrUpdateMeetingVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdateMeeting.init(this.recNo, recNo, sort + 1)
			})
		},
		addOrUpdateSituation(recNo, dataType) {
			// 计算最大排序值
			let sort = 0;
			if (dataType === '030101' && this.dataForm.designDrawingList != undefined && this.dataForm.designDrawingList.length > 0) {
				for(var i = 0; i < this.dataForm.designDrawingList.length; i++) {
					if(this.dataForm.designDrawingList[i].sort > sort) {
						sort = this.dataForm.designDrawingList[i].sort
					}
				}
			} else if (dataType === '030102' && this.dataForm.equipmentDataReviewList != undefined && this.dataForm.equipmentDataReviewList.length > 0) {
				for(var i = 0; i < this.dataForm.equipmentDataReviewList.length; i++) {
					if(this.dataForm.equipmentDataReviewList[i].sort > sort) {
						sort = this.dataForm.equipmentDataReviewList[i].sort
					}
				}
			} else if (dataType === '030103' && this.dataForm.productionDesignList != undefined && this.dataForm.productionDesignList.length > 0) {
				for(var i = 0; i < this.dataForm.productionDesignList.length; i++) {
					if(this.dataForm.productionDesignList[i].sort > sort) {
						sort = this.dataForm.productionDesignList[i].sort
					}
				}
			} else if (dataType === '030200' && this.dataForm.procurementProgressList != undefined && this.dataForm.procurementProgressList.length > 0) {
				for(var i = 0; i < this.dataForm.procurementProgressList.length; i++) {
					if(this.dataForm.procurementProgressList[i].sort > sort) {
						sort = this.dataForm.procurementProgressList[i].sort
					}
				}
			} else if (dataType === '030300' && this.dataForm.onsiteProductionProgressList != undefined && this.dataForm.onsiteProductionProgressList.length > 0) {
				for(var i = 0; i < this.dataForm.onsiteProductionProgressList.length; i++) {
					if(this.dataForm.onsiteProductionProgressList[i].sort > sort) {
						sort = this.dataForm.onsiteProductionProgressList[i].sort
					}
				}
			}
			this.addOrUpdateSituationVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdateSituation.init(this.recNo, recNo, sort + 1, dataType)
			})
		},
		getEnclosureFileList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportinfo/getEnclosureFileList/" + this.recNo,
				method: "get",
				params: {
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// this.dataForm.enclosureFileList = data.body
					 // 附件列表，重新设置附件名称
                    let enclosureFiles = []
                    if(data.body != undefined && data.body.length > 0) {
                        for(var i = 0; i < data.body.length; i++) {
                            data.body[i].fileName = '附件' + (i + 1 ) + "：" + data.body[i].fileName
                            enclosureFiles.push(data.body[i]);
                        }
                    }
                    this.dataForm.enclosureFileList = enclosureFiles;
					
				}
			});
		},
		uploadEnclosureFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadEnclosureFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/94/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					// 计算最大排序值
					let sort = 0;
					let enclosureFileList = this.dataForm.enclosureFileList
					if(enclosureFileList != undefined && enclosureFileList.length > 0) {
						for(var i = 0; i < enclosureFileList.length; i++) {
							if(enclosureFileList[i].sort > sort) {
								sort = enclosureFileList[i].sort
							}
						}
					}

					this.dataForm.enclosureFileList.push({
						filePath: res.body.fileSavePath,
						fileUri: res.body.fileUri,
						fileName: this.extractFileName(res.body.fileName),
						sort: sort + 1,
					});

					// 保存到db中
					let weeklyFileList = []
					let weeklyFile = {
						weeklyRecNo: this.recNo,
						dataType: "090000",
						subjectNo: '',
						sort: sort + 1,
						fileName: this.extractFileName(res.body.fileName),
						filePath: res.body.fileSavePath,
						sourceType: '',
						sourceId: ''
					}
					weeklyFileList.push(weeklyFile)
					this.saveWeeklyFileList(weeklyFileList, '090000')
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		onDragStart(dataType, index, index1, index2) {
			this.updateDataType = dataType;
			this.updateIndex = index;
			this.updateIndex1 = index1;
			this.updateIndex2 = index2;
		},
		onDragEnd(event) {
			event.preventDefault();
			// 拖拽结束后的处理逻辑
			let records = [];
			if (this.updateDataType == '020000') {
				// 2.本周主要时间综述
				records = this.dataForm.mainEventDescList
			} else if (this.updateDataType == '030000') {
				// 3 建造进度综述
				records = this.dataForm.constructionProgressList[this.updateIndex].conProgressList[this.updateIndex1].progressList[this.updateIndex2].progressDescList
			} else if (this.updateDataType == '030101') {
				// 3.1.1 详细设计图纸审查进度情况
				records = this.dataForm.designDrawingList
			} else if (this.updateDataType == '030102') {
				// 3.1.2 设备资料审查进度情况
				records = this.dataForm.equipmentDataReviewList
			} else if (this.updateDataType == '030103') {
				// 3.1.3 生产设计进展情况
				records = this.dataForm.productionDesignList
			} else if (this.updateDataType == '030200') {
				// 3.2 采购进度情况
				records = this.dataForm.procurementProgressList
			} else if (this.updateDataType == '030300') {
				// 3.3 现场人力资源投入情况
				records = this.dataForm.onsiteProductionProgressList
			} else if (this.updateDataType == '040100') {
				// 4.1 本周项目检验综述
				records = this.dataForm.testRecordSummaryList[this.updateIndex].testRecordList[this.updateIndex1].testRecordDescList
			} else if (this.updateDataType == '040200') {
				// 4.2 现场巡检综述
				records = this.dataForm.inspectionSummaryList[this.updateIndex].inspectionList[this.updateIndex1].inspectionDescList
			} else if (this.updateDataType == '050000') {
				// 5.设计修改及审图意见落实情况
				records = this.dataForm.designEditDesc
			} else if (this.updateDataType == '070000') {
				// 7. 安全生产/HSE情况
				records = this.dataForm.hseRecordList
			} else if (this.updateDataType == '080000') {
				// 8. 监理建议或意见
				records = this.dataForm.opinionList
			} 
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectweeklyreportdesc/sort",
				method: "post",
				data: this.$http.adornData({
					dataType: this.updateDataType,
					weeklyRecNo: this.recNo,
					records : records
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.updateDataType = ''
							this.getProjectWeeklyReportInfo();
						}
					});
				}
			});
		},
		dragSort() {
			const el = this.$refs.singleTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
			this.sortable = Sortable.create(el, {
				onEnd: e => {	//onEnd是结束拖拽时触发，onUpdate是列表内元素顺序更新的时候触发，更多请看文末配置项
					//e.oldIndex为拖动一行原来的位置，e.newIndex为拖动后新的位置
					const targetRow = this.dataForm.enclosureFileList.splice(e.oldIndex, 1)[0];
					this.dataForm.enclosureFileList.splice(e.newIndex, 0, targetRow);

					let reportFile = {
						fileList: this.dataForm.enclosureFileList,
						weeklyRecNo: this.recNo,
						subjectNo: '',
						dataType: '090000'
					}
					this.weeklyReportSort(reportFile, "090000")
				}
			})
		},
		viewEnclosureFile(url, filename) {
            filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
		selectContactEnclosureFile() {
			this.contactSelectVisible = true
			// 获取附件监理联系单来源ids
			let fileIds = ''
			let enclosureFileList = this.dataForm.enclosureFileList
			if(enclosureFileList != undefined && enclosureFileList.length > 0) {
				for(var i = 0; i < enclosureFileList.length; i++) {
					if(enclosureFileList[i].dataType === '090000' && enclosureFileList[i].sourceType === '4') {
						fileIds = fileIds + enclosureFileList[i].sourceId
						if(i < enclosureFileList.length - 1) {
							fileIds = fileIds + ','
						}
					}
				}
			}
			this.$nextTick(() => {
				this.$refs.contactSelect.init(this.dataForm.beginTime, this.dataForm.endTime, fileIds)
			})
		},
		margeSelectContactItems(items) {
			this.contactSelectVisible = false;
			// 计算最大排序值
			let sort = 0;
			let enclosureFileList = this.dataForm.enclosureFileList
			if(enclosureFileList != undefined && enclosureFileList.length > 0) {
				for(var i = 0; i < enclosureFileList.length; i++) {
					if(enclosureFileList[i].sort > sort) {
						sort = enclosureFileList[i].sort
					}
				}
			}
			let weeklyFileList = [];
			if(items != undefined && items.length > 0) {
				for(var i = 0; i < items.length; i++) {
					let weeklyFile = {
						weeklyRecNo: this.recNo,
						dataType: "090000",
						subjectNo: '',
						sort: sort + 1 + i,
						fileName: items[i].fileNo,
						filePath: items[i].filePath,
						sourceType: '4',
						sourceId: items[i].fileId
					}
					weeklyFileList.push(weeklyFile);
				}
			}
			this.saveWeeklyFileList(weeklyFileList, '090000')
		},
		selectSpecialReportEnclosureFile() {
			this.specialReportSelectVisible = true
			// 获取附件监理联系单来源ids
			let recIds = ''
			let enclosureFileList = this.dataForm.enclosureFileList
			if(enclosureFileList != undefined && enclosureFileList.length > 0) {
				for(var i = 0; i < enclosureFileList.length; i++) {
					if(enclosureFileList[i].dataType === '090000' && enclosureFileList[i].sourceType === '5') {
						recIds = recIds + enclosureFileList[i].sourceId
						if(i < enclosureFileList.length - 1) {
							recIds = recIds + ','
						}
					}
				}
			}
			this.$nextTick(() => {
				this.$refs.specialReportSelect.init(this.dataForm.beginTime, this.dataForm.endTime, recIds)
			})
		},
		margeSelectSpecialReportItems(items) {
			this.specialReportSelectVisible = false;
			// 计算最大排序值
			let sort = 0;
			let enclosureFileList = this.dataForm.enclosureFileList
			if(enclosureFileList != undefined && enclosureFileList.length > 0) {
				for(var i = 0; i < enclosureFileList.length; i++) {
					if(enclosureFileList[i].sort > sort) {
						sort = enclosureFileList[i].sort
					}
				}
			}
			let weeklyFileList = [];
			if(items != undefined && items.length > 0) {
				for(var i = 0; i < items.length; i++) {
					let weeklyFile = {
						weeklyRecNo: this.recNo,
						dataType: "090000",
						subjectNo: '',
						sort: sort + 1 + i,
						fileName: items[i].fileName,
						filePath: items[i].filePath,
						sourceType: '5',
						sourceId: items[i].recId
					}
					weeklyFileList.push(weeklyFile);
				}
			}
			this.saveWeeklyFileList(weeklyFileList, '090000')
		},
		selectMeetingEnclosureFile(dataType) {
			this.meetingSelectVisible = true
			this.meetDataType = dataType
			// 获取附件监理联系单来源ids
			let meetIds = ''
			// 会议模块
			if(dataType === '060000') {
				let meetingList = this.dataForm.meetingList
				if(meetingList != undefined && meetingList.length > 0) {
					for(var i = 0; i < meetingList.length; i++) {
						if(meetingList[i].sourceId != null && meetingList[i].sourceId != '') {
							meetIds = meetIds + meetingList[i].sourceId
							if(i < meetingList.length - 1) {
								meetIds = meetIds + ','
							}
						}
					} 
				}
			} else if(dataType === '090000') {
				let enclosureFileList = this.dataForm.enclosureFileList
				if(enclosureFileList != undefined && enclosureFileList.length > 0) {
					for(var i = 0; i < enclosureFileList.length; i++) {
						if(enclosureFileList[i].dataType === '090000' && enclosureFileList[i].sourceType === '3') {
							meetIds = meetIds + enclosureFileList[i].sourceId
							if(i < enclosureFileList.length - 1) {
								meetIds = meetIds + ','
							}
						}
					}
				}
			}
			this.$nextTick(() => {
				this.$refs.meetingSelect.init(this.dataForm.beginTime, this.dataForm.endTime, meetIds, '090000')
			})
		},
		margeSelectMeetingItems(items) {
			this.meetingSelectVisible = false;
			if (this.meetDataType === '060000') {
				let meetIds = items.map((item) => item.meetId);
				this.saveMeetingFiles(meetIds)
			} else if(this.meetDataType === '090000') {
				// 计算最大排序值
				let sort = 0;
				let enclosureFileList = this.dataForm.enclosureFileList
				if(enclosureFileList != undefined && enclosureFileList.length > 0) {
					for(var i = 0; i < enclosureFileList.length; i++) {
						if(enclosureFileList[i].sort > sort) {
							sort = enclosureFileList[i].sort
						}
					}
				}
				let weeklyFileList = [];
				if(items != undefined && items.length > 0) {
					for(var i = 0; i < items.length; i++) {
						let weeklyFile = {
							weeklyRecNo: this.recNo,
							dataType: "090000",
							subjectNo: '',
							sort: sort + 1 + i,
							fileName: items[i].meetNumber,
							filePath: items[i].filePath,
							sourceType: '3',
							sourceId: items[i].meetId
						}
						weeklyFileList.push(weeklyFile);
					}
				}
				this.saveWeeklyFileList(weeklyFileList, '090000')
			}
			this.meetDataType = ''

		},
		selectqpPhoto(type, index) {
			this.selectPhotoType = type
			this.selectQPPhotoVisible = true
			this.qpPhotoIndex = index
			let subjectNo;
			let photoList = []
			if(type === '2') {
				// 进度照片
				subjectNo = this.dataForm.progressPhotoList[index].subjectNo;
				photoList = this.dataForm.progressPhotoList[index].photoList
			} else {
				// 质量照片
				subjectNo = this.dataForm.qualityPhotoList[index].subjectNo;
				photoList = this.dataForm.qualityPhotoList[index].photoList
			}

			// 获取现场照片来源ids
			let recNos =''
			if(photoList != undefined && photoList.length > 0) {
				for(var i = 0; i < photoList.length; i++) {
					recNos = recNos + photoList[i].sourceId;
					if(i < photoList.length - 1) {
						recNos = recNos + ','
					}
				}	
			}
			this.$nextTick(() => {
				this.$refs.qpPhotoSelect.init(this.dataForm.beginTime, this.dataForm.endTime, type, subjectNo, recNos)
			})
		},
		margeSelectPhotoItems(items) {
			let weeklyFileList = [];
			if(this.selectPhotoType === '2') {
				// 进度照片
				// 计算最大排序值
				let sort = 0;
				let photoList = this.dataForm.progressPhotoList[this.qpPhotoIndex].photoList
				if(photoList != undefined && photoList.length > 0) {
					for(var i = 0; i < photoList.length; i++) {
						if(photoList[i].sort > sort) {
							sort = photoList[i].sort
						}
					}
				}

				if(items != undefined && items.length > 0) {
					for(var i = 0; i < items.length; i++) {
						let progressPhotot = {
							weeklyRecNo: this.recNo,
							dataType: "100100",
							subjectNo: items[i].subjectNo,
							sort: sort + 1 + i,
							fileName: items[i].photoContent,
							filePath: items[i].photoFilePath,
							sourceType: '1',
							sourceId: items[i].recNo
						}
						weeklyFileList.push(progressPhotot)
					}
				}
			} else {
				// 质量照片
				// 计算最大排序值
				let sort = 0;
				let photoList = this.dataForm.qualityPhotoList[this.qpPhotoIndex].photoList
				if(photoList != undefined && photoList.length > 0) {
					for(var i = 0; i < photoList.length; i++) {
						if(photoList[i].sort > sort) {
							sort = photoList[i].sort
						}
					}
				}
				if(items != undefined && items.length > 0) {
					for(var i = 0; i < items.length; i++) {
						let qualityPhoto = {
							weeklyRecNo: this.recNo,
							dataType: "100200",
							subjectNo: items[i].subjectNo,
							sort: sort + 1 + i,
							fileName: items[i].photoContent,
							filePath: items[i].photoFilePath,
							sourceType: '2',
							sourceId: items[i].recNo
						}
						weeklyFileList.push(qualityPhoto)
					}
				}
			}
			this.saveWeeklyFileList(weeklyFileList, '100000')
		},
		updateImgContent(recNo, photoContent) {
			this.updateImgContentVisable = true;
			this.photoRecNo = recNo;
			this.updatePhotoContent = photoContent;
		},
		saveImgContent(){
			// 直接更新到db
			let _url = "/business/projectweeklyreportfile/updatePhotoContent";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					recNo: this.photoRecNo,
					fileName: this.updatePhotoContent
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.updateImgContentVisable = false
					this.getScenePhotoList()
				}
			});
			this.getScenePhotoList();
			this.getConstructionProgressList();
			this.getWeeklySituationList()
		}
	},

};
</script>
<style lang="scss" scoped="scoped">

.elCardTitle {
    color: black !important;
    font-size: 15px;
    border-bottom: 1px solid black;
}

border-form .el-row.set_border {
	border: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 2px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}

.col-set_border1 {
	border: solid #dadada 1px;
}

.col-set_border2 {
	border: solid #dadada 1px;
	border-left: solid #dadada 0px;
}

.col-set_border3 {
	border: solid #dadada 1px;
	border-top: solid #dadada 0px;
}

.col-set_border4 {
	border-right: solid #dadada 1px;
	border-bottom: solid #dadada 1px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
	border: solid #dadada 1px;
}

.summaryLabelspan {
    display: inline-block;
    // margin-left: 10px;
    color: #00428E;
    width: 250px !important;
    border: solid #dadada 1px;
    padding-left: 10px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 30px;
}
.summaryLabelspan1 {
    display: inline-block;
    // margin-left: 10px;
    color: #00428E;
    border: solid #dadada 1px;
    border-bottom: solid #dadada 0px;
    padding-left: 10px;
    font-size: 13px;
    margin-right: 30px;
	// width: 1095%;
}

.contact_col {
	border: 1px solid rgba(235, 238, 240, 1);
	//  border: solid #dadada 1px;
	 color: #00428E;
	//  padding-top: -10px !important;
	 padding-left: 15px;
	 height: 40px;
}

.titleDiv {
	background-color: #E1F1FF;
	font-size: 15px;
	margin-top: 20px;
	margin-bottom: 12px;
	padding: 10px 0px 10px 10px;
	font-weight: bolder;
}

::v-deep(.sp-input .el-input__inner) {
	width: 140% !important;
}

.selBtnGroup {
	margin-top: 5px;
	margin-bottom: 5px;
	margin-right: 20px !important;
}

::v-deep .desc_input .el-input__inner {
    border-radius: 0px;
	border-width: 0px ;
    width: 100% !important;
	white-space: pre-wrap !important;
	overflow-wrap: break-word !important;
	// white-space: pre-line !important;
	color: black;
	padding: 0px !important;
	margin-top: -10px;
	font-size: 14px;
	margin-top:1px;
	// border-bottom: solid #dadada 1px;
}

.desc_span {
	padding-top:2px;
	line-height: 22px;
	border-bottom: solid transparent 1px;
	white-space: pre-line
}

.desc_span_color {
	border-bottom: solid transparent 1px;
	white-space: pre-line
}

::v-deep .desc_input_color .el-input__inner {
    border-radius: 0px;
	border-width: 0px ;
    width: 100% !important;
	white-space: pre-line;
	color: black;
	padding: 0px !important;
	margin-top: -10px;
	font-size: 14px;
	background-color: #e1f1ff !important;
	height:30px;
}

.un_col {
	border: 1px solid transparent;
	text-align: center;	
	padding-top: 9px;
	// font-size: 13px;
}
.un_span{
	font-weight: 400;
    font-style: normal;
    font-size: 13px;
	color: #169BD5;
}

.desc_btn {
	font-weight: 400;
    font-style: normal;
    font-size: 13px;
	color: #169BD5;
}

.cp_sub_row {
	// border-bottom: 1px solid #00428E;
	font-size: 13px;
	background-color: rgb(204, 247, 176);
	padding: 10px;
	color: #00428E;
}

.cp_maj_row{
	color: #00428E;
	background-color: #a9ccf5;
	padding: 10px;
	border-top: 1px solid #00428E;
}

.cp_sc_div {
	border: 1px solid rgba(235, 238, 240, 1);
	padding: 8px;
	margin-left:10px;
	margin-top: 5px;
	font-weight: bold;
	font-size: 13px;
	width: 97%;
}

.el-icon-position {
	margin:14px;
}

.tr_title_row {
	border: 1px solid rgba(235, 238, 240, 1);
	font-weight: bolder;
	color: #00428E;
	font-size: 14px;
	padding: 15px;
}

.tr_title_row1 {
	font-size: 14px;
	padding: 15px;
}

.tr_qp_row {
	border: 1px solid rgba(235, 238, 240, 1);
	font-weight: bolder;
	font-size: 14px;
	padding: 15px;
	text-align:center
}

.cv_span {
	display: inline-block;
	width:70px;
	text-align:center;
	background-color: #dadada;
	margin-left: 10px;
	margin-right: 10px;
}

.addImgBox {
    width: 180px;
    height: 180px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 23px;
}

// .image {
// 	width: 80%;
// 	height: 200px;
// 	margin-top: 5px;
// 	display: block;
// }

.photo_row {
	border: 1px solid rgba(235, 238, 240, 1);
	width:80%;
	padding-bottom: 20px;
	padding-top: 20px;
	margin-top:10px
}

.meet_row {
	border: 1px solid rgba(235, 238, 240, 1);
	width:100%;
	padding-bottom: 20px;
	padding-top: 20px;
	margin-top:10px
}

.photo_col {
	border: 1px solid rgba(235, 238, 240, 1);
}

.t-column-ellipsis {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	padding-bottom: 10px;
	height: 50px;
}

.set_bc {
	border-bottom: solid #dadada 1px;
	background-color:  #e1f1ff !important;
	// height:35px !important;
	padding-top:8px;
	padding-left:5px;
}

.set_non {
	border-bottom: solid #dadada 1px;
	// height:38px !important;
	padding-top:8px;
	padding-left:5px;
}

.mc_div {
	margin-left: 20px;
	border: 1px solid rgba(235, 238, 240, 1);
	padding: 10px;
	width: 90%;
	margin-bottom: -20px
}

::v-deep .desc_input1 .el-textarea__inner {
    // padding-right: 50px !important;
     border: 0px; 
	 color: black;
	 resize: none;
}

::v-deep .desc_input_bc .el-textarea__inner {
    // padding-right: 50px !important;
     border: 0px; 
	 color: black;
	 background-color: #e1f1ff;
	 resize: none;
}

.set_span {
	display: block; /* 将span转换为块级元素 */
	max-width: 275px; /* 设置固定长度 */
	white-space: nowrap; /* 不换行 */
	overflow: hidden; /* 超出部分隐藏 */
	text-overflow: ellipsis; /* 超出部分显示省略号 */
	cursor: default; /* 将鼠标光标设置为默认样式 */
}

.desc_zw {
	font-weight: 400;
    font-style: normal;
    font-size: 13px;
	color: white;
}

.desc_zw_bc {
	font-weight: 400;
    font-style: normal;
    font-size: 13px;
	color: #e1f1ff;
}

.sesit {
	margin-left: 15px;
	margin-bottom: 10px;
	margin-top:10px;
}

</style>
